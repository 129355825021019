import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { userCollectionRef } from '../../../../utils/collectionRefs'
import { addDoc } from 'firebase/firestore'
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { toast } from 'react-toastify'
import { Loader } from '../../../../components/Loader/Loader'
import CustomDialog from '../../../../components/CustomDialog/CustomDialog'
import OrderGrid from '../../../../components/OrderGrid '
import { kassaTransactionColumns } from './../KassIn/constants/getKassaTransactionColumns';
import axios from 'axios'
import dayjs from 'dayjs'
import { getAuthToken, verifyToken } from '../../../Auth/Login/authToken'
import { useDetailKassa } from './helpers/useDetailKassa';
import { handleSignOut } from '../../../../configs'
import BalanceCard from '../../../../components/BalanceCard/BalanceCard'
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import Pko from '../../../../components/Form/Pko'
import Rko from '../../../../components/Form/Rko'

const KassaDetails = ({ pageTitle }) => {
  const { id } = useParams()
  const { kassa, loading, refetch } = useDetailKassa(id)
  const [kassaTransaction, setKassaTransaction] = useState([])
  const [types, setTypes] = useState([])
  const [operation, setOperation] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [kassLoading, setKassaLoading] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState({});
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');
  const navigate = useNavigate()
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');

  const [balanceBefore, setBalanceBefore] = useState(0)
  const [balanceAfter, setBalanceAfter] = useState(0)

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [transFormData, setTransFormData] = useState(null)
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleDateChange2 = (e) => setSelectedDate2(e.target.value);

  useEffect(() => {
    const checkTokenAndRedirect = async () => {
      try {
        await verifyToken();
      } catch (error) {
        console.error('Ошибка при проверке токена:', error);
        handleSignOut()
      }
    };

    checkTokenAndRedirect();
  }, [id]);

  useEffect(() => {
    const getKassaTypes = async () => {
      try {
        const response = await axios.get(`https://aylex.ru/api/core/type/`);

        if (response.status === 200) {
          setTypes(response.data.results);
        } else {
          console.error('Не удалось получить типы', response.status);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getKassaTypes();
  }, []);

  const fetchKassaTransactions = useCallback(
    async (page = 1, pageSize = 20) => {
      setKassaLoading(true);
      try {
        const filters = {
          kassa: id,
          ...Object.keys(selectedFilters).reduce((acc, field) => {
            const value = selectedFilters[field];
            if (value !== 'Все' && value !== '') {
              acc[field] = value;
            }
            return acc;
          }, {}),
        };

        if (selectedDate) {
          filters.start_date = selectedDate;
        }
        if (selectedDate2) {
          filters.end_date = selectedDate2;
        }

        const token = await getAuthToken();
        const response = await axios.get(`https://aylex.ru/api/core/kassaTransaction/`, {
          params: { ...filters, page, page_size: pageSize },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const transactions = response.data.results;
          const balanceBefore = transactions.length > 0 ? transactions[transactions.length - 1].balanceBefore : 0;
          const balanceAfter = transactions.length > 0 ? transactions[0].balanceAfter : 0;
          setKassaTransaction(response.data.results);
          setTotalRowCount(response.data.count);
          setBalanceBefore(balanceBefore);
          setBalanceAfter(balanceAfter);
        } else {
          console.error('Не удалось получить данные транзакций', response.status);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setKassaLoading(false);
      }
    },
    [id, selectedFilters, selectedDate, selectedDate2]
  );


  useEffect(() => {
    fetchKassaTransactions(currentPage, pageSize);
  }, [fetchKassaTransactions, currentPage, pageSize]);

  useEffect(() => {
    const getKassaTransactions = async () => {
      setKassaLoading(true)
      try {
        const filters = {
          kassa: id,
          ...Object.keys(selectedFilters).reduce((acc, field) => {
            const value = selectedFilters[field];
            if (value !== 'Все' && value !== '') {
              acc[field] = value;
            }
            return acc;
          }, {}),
        };

        if (selectedDate) {
          filters.start_date = selectedDate;
        }
        if (selectedDate2) {
          filters.end_date = selectedDate2;
        }

        const token = await getAuthToken();
        const response = await axios.get(`https://aylex.ru/api/core/kassaTransaction/`, {
          params: filters,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          const transactions = response.data.results;
          const balanceBefore = transactions.length > 0 ? transactions[transactions.length - 1].balanceBefore : 0;
          const balanceAfter = transactions.length > 0 ? transactions[0].balanceAfter : 0;
          setKassaTransaction(response.data.results);
          setKassaLoading(false)
          setBalanceBefore(balanceBefore);
          setBalanceAfter(balanceAfter);
        } else {
          console.error('Не удалось получить данные транзакций', response.status);
        }
      } catch (error) {
        setKassaLoading(false)
        console.error(error);
      }
    };

    getKassaTransactions();
  }, [selectedFilters, selectedDate, selectedDate2, id]);

  const handleDeleteKassa = async () => {
    const userHistoryData = {
      date: new Date(),
      description: `${userType} ${userName} удалил кассу с названием ${kassa?.name}`,
    };

    try {
      const response = await axios.delete(`https://aylex.ru/api/core/kassa/${id}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.status === 204) {
        toast.success('Касса успешно была удалена', {
          autoClose: 2000,
          hideProgressBar: false,
        });

        await addDoc(userCollectionRef, userHistoryData);

        navigate('/bank');
      } else {
        throw new Error('Не удалось удалить кассу');
      }
    } catch (error) {
      toast.error('Произошла ошибка при удалении кассы', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      console.error('Error deleting kassa:', error);
    }
  };
  const handleExport = async () => {
    try {
      const filters = {
        kassa: id,
        ...Object.keys(selectedFilters).reduce((acc, field) => {
          const value = selectedFilters[field];
          if (value !== 'Все' && value !== '') {
            acc[field] = value;
          }
          return acc;
        }, {}),
      };

      if (selectedDate) {
        filters.start_date = selectedDate;
      }
      if (selectedDate2) {
        filters.end_date = selectedDate2;
      }
      const response = await axios.get(`https://aylex.ru/api/core/export/kassa-transactions/${id}/`, {
        params: filters
      });

      if (response.data?.file) {
        const link = document.createElement('a');
        link.href = response.data.file;
        link.download = response.data.title || 'exported_file.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Файл не найден в ответе');
      }
    } catch (error) {
      console.error('Ошибка при экспорте:', error);
    }
  };

  const onSubmitKassaTransaction = async (data) => {
    let loadingToastId;
    const newAmount = parseInt(data.summa) * (operation === 'subtract' ? -1 : 1);
    const fullDescription = `${userType} ${userName} отредактировал баланс кассы`;

    const kassaTransactionData = {
      summa: parseInt(newAmount),
      type: Number(data?.type),
      comment: data.comment ?? '',
      kassa: Number(id),
      dateTransaction: '',
      dateCreated: new Date(),
      author: `${roleType} ${userName}`,
      balanceBefore: kassa?.balance || 0,
      balanceAfter: (kassa?.balance || 0) + newAmount,
    };
    try {
      handleClose();

      const token = localStorage.getItem('authToken')

      loadingToastId = toast.loading(`Добавление транзакции кассы...`, {
        autoClose: true,
      });
      await axios.post('https://aylex.ru/api/core/kassaTransaction/', kassaTransactionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success('Транзакция кассы успешно добавлена');
      toast.dismiss(loadingToastId);
      refetch();
      await fetchKassaTransactions();
      await addDoc(userCollectionRef, {
        date: new Date(),
        description: fullDescription,
      })
      setTransFormData(data);
      setIsResultModalOpen(true);
      reset();
    } catch (error) {
      console.error('Error adding transaction: ', error);
      toast.error('Произошла ошибка!', {
        autoClose: 2000,
        hideProgressBar: true,
      });
      setTransFormData(null);
      setIsResultModalOpen(false);
      toast.dismiss(loadingToastId);
    }
  };

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event?.target?.value);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  const kassaTypeToSort = createOrderSortHandler('type');

  const handleOpen = (op) => {
    setOperation(op);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    reset();
  };
  const handleClickClose = () => {
    setOpenDeleteModal(false);
  };
  const columns = kassaTransactionColumns()

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Список касс' initial='Детали кассы' />
      <Title title='Детали кассы' icon={<AssignmentIndRoundedIcon fontSize='large' />} />
      <div className="container-inner">
        {loading ? <Loader /> : (
          <div className="courier-more-wrapper">
            <div className="courier-more-card сlient">
              <div className='courier-card-right-side'>
                <div className='courier-card-list client'>
                  <span>
                    <p>Название:</p>
                    <p>{kassa?.name}</p>
                  </span>
                  <span>
                    <p>Дата создания:</p>
                    <p>{dayjs(kassa?.dateCreated).format('DD/MM/YYYY')}</p>
                  </span>
                  <span>
                    <p>Комментарии:</p>
                    <p>{kassa?.comment}</p>
                  </span>
                </div>
                <div className='courier-card-list client'>
                  <span>
                    <p>Баланс:</p>
                    <p>{Math.floor(kassa?.balance).toLocaleString()}c</p>
                  </span>
                </div>
                <div className='courier-card-list client'>
                  <Box display='flex' gap='7px' className='courier-button-list'>
                    {userType === 'Админ' && (
                      <>
                        <Button
                          variant='black-contained'
                          onClick={() => navigate(`/courier/update/${kassa?.id}`)}
                        >
                          <EditRoundedIcon />
                        </Button>
                        {/* <Button
                          variant='black-contained'
                          onClick={() => setOpenDeleteModal(true)}
                        >
                          <DeleteRoundedIcon />
                        </Button> */}
                      </>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <div className='courier-avatar-card'>
              <div className='courier-balance-card courier-balance-card__two'>
                <div className='courier-balance-card-title'>
                  <MonetizationOnRoundedIcon style={{ color: '#10B981' }} />
                  <p>Balance</p>
                </div>
                <div className='courier-balance-card-data'>
                  <p>{(kassa?.balance ?? 0).toLocaleString()}с</p>
                </div>
                <div className='courier-balance-btn-container'>
                  <Button
                    variant='contained'
                    size='md'
                    className='courier-more-btn'
                    onClick={() => handleOpen('add')}
                    disabled={!kassa}
                  >
                    <AddRoundedIcon />
                  </Button>
                  <Button
                    variant='contained'
                    size='md'
                    className='courier-more-btn'
                    onClick={() => handleOpen('subtract')}
                    disabled={!kassa}
                  >
                    <RemoveRoundedIcon />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="orders-sorting-header clear-btn" style={{ marginTop: '20px' }}>
          <Stack flexDirection='row' gap={1}>
            <div className='order-sort'>
              <TextField
                id='outlined-select-currency'
                select
                label='По типу'
                fullWidth
                defaultValue=''
                onChange={kassaTypeToSort}
              >
                <MenuItem value='Все'>Все</MenuItem>
                {types?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div className="order-sort order-sort-date">
              <TextField
                type='date'
                size='small'
                focused
                color='warning'
                value={selectedDate || ''}
                onChange={handleDateChange}
                label='Начало'
              />
            </div>
            <div className="order-sort order-sort-date">
              <TextField
                type='date'
                size='small'
                focused
                color='warning'
                value={selectedDate2 || ''}
                onChange={handleDateChange2}
                label='Конец'
              />
            </div>
          </Stack>
        </div>
        <Box display='flex' gap={2} mt={2.3}>
          <BalanceCard
            title='Сальдо начало'
            value={balanceBefore}
            icon={NumbersRoundedIcon}
            iconColor='#635BFF'
          />
          <BalanceCard
            title='Сальдо конец'
            value={balanceAfter}
            icon={NumbersRoundedIcon}
            iconColor='#635BFF'
          />
        </Box>
        <div className='orders-card-wrapper' style={{ background: 'white', marginTop: '20px' }}>
          <div className='orders-card-header'>
            <Button variant='black-contained' onClick={handleExport}>
              Экспорт
            </Button>

          </div>

          <OrderGrid
            orders={kassaTransaction || []}
            columns={columns}
            size={pageSize}
            page={currentPage - 1}
            totalRowCount={totalRowCount}
            loading={kassLoading}
            usePagination={true}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />

        </div>

        <Dialog open={openModal} onClose={handleClose} maxWidth='xs'>
          <DialogTitle>
            Изменение баланса {operation === 'subtract' ? '(минус баланс)' : '(плюс баланс)'}
          </DialogTitle>
          <form>
            <DialogContent>
              <Controller
                name='summa'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле*', min: 1 }}
                render={({ field }) => (
                  <TextField
                    autoFocus
                    margin='dense'
                    id='summa'
                    label='Сумма'
                    type='number'
                    fullWidth
                    error={!!errors.summa}
                    {...field}
                  />
                )}
              />
              <Controller
                name='acceptedFrom'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле*' }}
                render={({ field }) => (
                  <TextField
                    margin='dense'
                    id='acceptedFrom'
                    label={operation === 'subtract' ? 'Выдать' : 'Принято от'}
                    fullWidth
                    error={!!errors.acceptedFrom}
                    helperText={errors?.acceptedFrom?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name='comment'
                control={control}
                defaultValue=''
                rules={{ required: 'Обязательное поле*' }}
                render={({ field }) => (
                  <TextField
                    margin='dense'
                    id='comment'
                    label='Описание'
                    fullWidth
                    error={!!errors.comment}
                    helperText={errors?.comment?.message}
                    {...field}
                  />
                )}
              />
              <Controller
                name="type"
                control={control}
                defaultValue={null}
                rules={{ required: "Обязательное поле*" }}
                render={({ field }) => (
                  <Autocomplete
                    options={types}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    onChange={(_, newValue) => field.onChange(newValue?.id)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Тип"
                        margin="dense"
                        fullWidth
                        error={!!errors.type}
                        helperText={errors?.type?.message}
                      />
                    )}
                  />
                )}
              />

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} sx={{ color: '#000b1f' }} variant='text'>
                Отмена
              </Button>
              <Button
                type='submit'
                variant='text'
                sx={{ color: '#000b1f' }}
                onClick={handleSubmit(onSubmitKassaTransaction)}
              >
                {operation === 'subtract' ? 'Списать' : 'Добавить'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <CustomDialog
          open={openDeleteModal}
          onClose={handleClickClose}
          event={() => handleDeleteKassa()}
          text='Вы уверены что хотите удалить кассу?'
          btnText='Удалить'
        />
        <hr />
        <Dialog open={isResultModalOpen} onClose={() => setIsResultModalOpen(false)} maxWidth="xl">
          <DialogTitle>{operation === "add" ? "Приходный кассовый ордер (ПКО)" : "Расходный кассовый ордер (РКО)"}</DialogTitle>
          <DialogContent>
            {operation === "add" ? <Pko formData={transFormData} /> : <Rko formData={transFormData} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsResultModalOpen(false)}>Закрыть</Button>
          </DialogActions>
        </Dialog>
        {/* <Box sx={{ width: '100%', mt: 2 }}>
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            centered
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="ПКО" />
            <Tab label="РКО" />
          </Tabs>

          <Box sx={{ padding: 2 }}>
            {tabIndex === 0 && <Pko formData={operation === 'add' ? transFormData : null} />}
            {tabIndex === 1 && <Rko formData={operation === 'subtract' ? transFormData : null} />}
          </Box>
        </Box> */}
      </div>
    </div>
  )
}

export default KassaDetails