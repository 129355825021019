export const transactionStatusLabels = {
  fuel: 'Бензин',
  parking: 'Парковка',
  taxi: 'Такси',
  courier_cost: 'Доля курьера',
  delivery: 'Услуга доставки',
  vykup_minus: 'Оплата за выкуп',
  vykup_plus: 'Получение суммы выкупа',
  admin_plus: 'Админ плюс',
  admin_minus: 'Админ минус',
  vokzal: 'Вокзал',
  return_vykup: 'Получение выкупа (Возврат)',
  return_delivery: 'Получение за услуги доставки (Возврат)',
  cancel_order: 'Отмена заказа',
  detail_change: 'Изменение в деталях',
  other: 'Другое',
};

export const typeOfTransactionLables = [
  { value: 'fuel', name: 'Бензин' },
  { value: 'parking', name: 'Парковка' },
  { value: 'taxi', name: 'Такси' },
  { value: 'courier_cost', name: 'Доля курьера' },
  { value: 'delivery', name: 'Услуга доставки' },
  { value: 'vykup_minus', name: 'Оплата за выкуп' },
  { value: 'vykup_plus', name: 'Получение суммы выкупа' },
  { value: 'admin_plus', name: 'Админ плюс' },
  { value: 'admin_minus', name: 'Админ минус' },
  { value: 'kassa', name: 'Касса' },
  { value: 'vokzal', name: 'Вокзал' },
  { value: 'return_vykup', name: 'Получение выкупа (Возврат)' },
  { value: 'return_delivery', name: 'Получение за услуги доставки (Возврат)' },
  { value: 'cancel_order', name: 'Отмена заказа' },
  { value: 'detail_change', name: 'Изменение в деталях' },
  { value: 'other', name: 'Другое' },
];

export const transactionExpenseLabels = {
  detail_change: 'Корректировка выкупа',
  detail_change_usluga: 'Корректировка услуги доставки',
  admin_plus: 'Выдача денежных средств в подотчет в (1520)',
  admin_minus: 'Возврат подотчетных денежных средств (1520)',
  salary_aud: 'Заработная плата АУП',
  salary_couriers_hourly: 'Заработная плата курьеров (повременщики)',
  courier_service_expenses: 'Расходы по оплате услуг (курьера)',
  advertising_expenses: 'Расходы на рекламу и содействие продаже',
  fuel_compensation_couriers: 'Компенсация затрат на топливо курьеров',
  packaging_expenses: 'Расходы по упаковке товаров',
  office_supplies_expenses: 'Расходы на канцелярские принадлежности',
  leasing_sprinter: 'Лизинг авто Спринтер',
  leasing_kia_rio: 'Лизинг авто Киа Рио',
  car_repair_expenses: 'Расходы на ремонт и запчасти машин',
  car_service_sprinter: 'Расходы на обслуживание машин (Спринтер)',
  fuel_admin: 'Топливо администрации',
  courier_communication_expenses: 'Расходы на связь для курьеров',
  office_equipment_purchase: 'Покупка конторских оборудований',
  office_rent_expenses: 'Расходы по оплате аренды офиса',
  furniture_purchase: 'Покупка Мебеля и принадлежностей',
  equipment_maintenance_expenses: 'Затраты на ремонт и обслуживание основных средств',
  property_improvement_expenses: 'Благоустройство арендованной собственности',
  land_improvement_expenses: 'Благоустройство земельных участков',
  electricity_expenses: 'Затраты на электроэнергию',
  water_supply_expenses: 'Затраты на водоснабжение',
  waste_management_expenses: 'Затраты за мусор',
  local_travel_expenses: 'Командировочные расходы (местные)',
  international_travel_expenses: 'Командировочные расходы (международные)',
  software_expenses: 'Расходы по компьютерному обеспечению - программное обеспечение',
  banking_commission_expenses: 'Расходы на комиссии, открытие счетов и Банковское обслуживание',
  bad_debt_expenses: 'Расходы по безнадежным долгам, относящимся к реализации',
  unsold_product_costs: 'Возврат товаров и услуг от покупателя',
  returns_revenue: 'Выручка от реализации услуг за возвращаемый товар',
  sold_product_costs: 'Себестоимость реализованной продукции, услуг',
  services_revenue: 'Выручка от реализации услуг',
  goods: 'Товар',
  irrecoverable_debts_expenses: 'Расходы по безнадежным долгам',
  miscellaneous_expenses: 'Прочие расходы',
  household_goods: 'Хозтовары',
  representation_expenses: 'Представительские расходы',
  auditor_rewards: 'Вознаграждение аудиторам',
  lawyer_rewards: 'Вознаграждение юристам',
  training_expenses: 'Расходы по обучению',
  consultation_expenses: 'Расходы по консультациям',
  public_relations_expenses: 'Расходы по связям с общественностью',
  budget_fines: 'Штрафы, пени, неустойки в бюджет',
  contract_fines: 'Штрафы, пени, неустойки по хозяйственным договорам',
  asset_amortization_expenses: 'Расходы на амортизацию основных средств',
  programmer_expenses: 'Расходы на программиста',
  employee_taxi_expenses: 'Такси для сотрудников',
  cash_withdrawal_expenses: 'Вывод средств из оборота',
  taxes_and_social_fund: 'Налоги и соцфонд',
  income_tax: 'Подоходный налог на доходы физических лиц',
  insurance_contributions: 'Страховые взносы',
  other_taxes: 'Прочие налоги к оплате',
  single_tax: 'Единый налог',
  investment: 'Инвестиция',
  investment_return: 'Возврат инвестиций',
  dividend_payment: 'Оплата дивидендов',
};

export const typeOfTransactionExpenseLables = [
  { value: 'admin_plus', name: 'Выдача денежных средств в подотчет в (1520)' },
  { value: 'admin_minus', name: 'Возврат подотчетных денежных средств (1520)' },
  { value: 'salary_aud', name: 'Заработная плата АУП' },
  { value: 'salary_couriers_hourly', name: 'Заработная плата курьеров (повременщики)' },
  { value: 'courier_service_expenses', name: 'Расходы по оплате услуг (курьера)' },
  { value: 'advertising_expenses', name: 'Расходы на рекламу и содействие продаже' },
  { value: 'detail_change', name: 'Корректировка выкупа' },
  { value: 'detail_change_usluga', name: 'Корректировка услуги доставки' },
  { value: 'fuel_compensation_couriers', name: 'Компенсация затрат на топливо курьеров' },
  { value: 'packaging_expenses', name: 'Расходы по упаковке товаров' },
  { value: 'office_supplies_expenses', name: 'Расходы на канцелярские принадлежности' },
  { value: 'leasing_sprinter', name: 'Лизинг авто Спринтер' },
  { value: 'leasing_kia_rio', name: 'Лизинг авто Киа Рио' },
  { value: 'car_repair_expenses', name: 'Расходы на ремонт и запчасти машин ' },
  { value: 'car_service_sprinter', name: 'Расходы на обслуживание машин (Спринтер)' },
  { value: 'fuel_admin', name: 'Топливо администрации' },
  { value: 'courier_communication_expenses', name: 'Расходы на связь для курьеров' },
  { value: 'office_equipment_purchase', name: 'Покупка конторских оборудований' },
  { value: 'office_rent_expenses', name: 'Расходы по оплате аренды офиса' },
  { value: 'furniture_purchase', name: 'Покупка Мебеля и принадлежностей' },
  { value: 'equipment_maintenance_expenses', name: 'Затраты на ремонт и обслуживание основных средств' },
  { value: 'property_improvement_expenses', name: 'Благоустройство арендованной собственности' },
  { value: 'land_improvement_expenses', name: 'Благоустройство земельных участков' },
  { value: 'electricity_expenses', name: 'Затраты на электроэнергию' },
  { value: 'water_supply_expenses', name: 'Затраты на водоснабжение' },
  { value: 'waste_management_expenses', name: 'Затраты за мусор' },
  { value: 'local_travel_expenses', name: 'Командировочные расходы (местные)' },
  { value: 'international_travel_expenses', name: 'Командировочные расходы (международные)' },
  { value: 'software_expenses', name: 'Расходы по компьютерному обеспечению - программное обеспечение' },
  { value: 'banking_commission_expenses', name: 'Расходы на комиссии, открытие счетов и Банковское обслуживание' },
  { value: 'bad_debt_expenses', name: 'Расходы по безнадежным долгам, относящимся к реализации' },
  { value: 'unsold_product_costs', name: 'Возврат товаров и услуг от покупателя' },
  { value: 'returns_revenue', name: 'Выручка от реализации услуг за возвращаемый товар ' },
  { value: 'sold_product_costs', name: 'Себестоимость реализованной продукции, услуг' },
  { value: 'services_revenue', name: 'Выручка от реализации услуг' },
  { value: 'goods', name: 'Товар' },
  { value: 'irrecoverable_debts_expenses', name: 'Расходы по безнадежным долгам' },
  { value: 'miscellaneous_expenses', name: 'Прочие расходы' },
  { value: 'household_goods', name: 'Хозтовары' },
  { value: 'representation_expenses', name: 'Представительские расходы' },
  { value: 'auditor_rewards', name: 'Вознаграждение аудиторам' },
  { value: 'lawyer_rewards', name: 'Вознаграждение юристам' },
  { value: 'training_expenses', name: 'Расходы по обучению' },
  { value: 'consultation_expenses', name: 'Расходы по консультациям' },
  { value: 'public_relations_expenses', name: 'Расходы по связям с общественностью' },
  { value: 'budget_fines', name: 'Штрафы, пени, неустойки в бюджет' },
  { value: 'contract_fines', name: 'Штрафы, пени, неустойки по хозяйственным договорам' },
  { value: 'asset_amortization_expenses', name: 'Расходы на амортизацию основных средств' },
  { value: 'programmer_expenses', name: 'Расходы на программиста' },
  { value: 'employee_taxi_expenses', name: 'Такси для сотрудников' },
  { value: 'cash_withdrawal_expenses', name: 'Вывод средств из оборота ' },
  { value: 'taxes_and_social_fund', name: 'Налоги и соцфонд' },
  { value: 'income_tax', name: 'Подоходный налог на доходы физических лиц' },
  { value: 'insurance_contributions', name: 'Страховые взносы' },
  { value: 'other_taxes', name: 'Прочие налоги к оплате' },
  { value: 'single_tax', name: 'Единый налог' },
  { value: 'investment', name: 'Инвестиция' },
  { value: 'investment_return', name: 'Возврат инвестиций' },
  { value: 'dividend_payment', name: 'Оплата дивидендов' },
];

