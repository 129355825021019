import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { handleSignOut } from '../../configs/index';
import LogoutRoundedIcon from '@mui/icons-material/LogoutOutlined';
import Logo from '../../assets/images/logo.svg';
import MobileLogo from '../../assets/images/mobile-logo.svg';
import './Navbar.scss';
import { List, ListItemButton, ListItemText, ListItemIcon, Collapse } from '@mui/material';
import {
  orderLinks,
  accountingLinks,
  couriersLinks,
  marketplaceLinks,
  otherLinks,
  mainLinks,
  couriersAdminLinks
} from './constants';
import { MdKeyboardArrowRight, MdOutlineExpandMore } from 'react-icons/md'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';

import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import { useSidebar } from '../../provider/SidebarProvider';


const mainSections = [
  {
    sectionName: 'main',
    title: 'Главные',
    icon: <WindowOutlinedIcon />,
    links: mainLinks,
    defaultOpen: true,
  },
  {
    sectionName: 'orders',
    title: 'Заказы (списки)',
    icon: <AssignmentOutlinedIcon />,
    links: orderLinks,
  },
  {
    sectionName: 'accounting',
    title: 'Бухгалтерия',
    icon: <MonetizationOnOutlinedIcon />,
    links: accountingLinks,
  },
  {
    sectionName: 'couriers',
    title: 'Курьеры',
    icon: <PeopleAltOutlinedIcon />,
    links: couriersLinks,
  },
  {
    sectionName: 'marketplace',
    title: 'Маркетплейс',
    icon: <StoreMallDirectoryOutlinedIcon />,
    links: marketplaceLinks,
  },
  {
    sectionName: 'other',
    title: 'Другое',
    icon: <AttachmentOutlinedIcon />,
    links: otherLinks,
  },
];

const courierSections = [
  {
    sectionName: 'main',
    title: 'Главные',
    icon: <WindowOutlinedIcon />,
    links: couriersAdminLinks,
    defaultOpen: true,
  },
 

];

const NavbarCollapse = () => {
  const location = useLocation();
  const { isCollapsed } = useSidebar();
  const [user, setUser] = React.useState(localStorage.getItem('userType'));

  React.useEffect(() => {
    const handleUserChange = () => {
      setUser(localStorage.getItem('userType'));
    };

    window.addEventListener('storage', handleUserChange);

    return () => {
      window.removeEventListener('storage', handleUserChange);
    };
  }, []);

  const sections = user === 'Курьер' ? courierSections : mainSections;

  const [openSections, setOpenSections] = React.useState(() => {
    const savedState = localStorage.getItem('openSections');
    return savedState
      ? JSON.parse(savedState)
      : sections.reduce((acc, section) => {
        acc[section.sectionName] = section.defaultOpen || false;
        return acc;
      }, {});
  });

  const isActiveSection = (sectionLinks) => {
    return sectionLinks.some(({ path }) => location.pathname.startsWith(path));
  };

  React.useEffect(() => {
    localStorage.setItem('openSections', JSON.stringify(openSections));
  }, [openSections]);

  React.useEffect(() => {
    const activeSection = sections.find((section) =>
      section.links.some((link) => location.pathname.startsWith(link.path))
    );
    if (activeSection) {
      setOpenSections((prev) => ({
        ...prev,
        [activeSection.sectionName]: true,
      }));
    }
  }, [location.pathname, sections]);

  const handleToggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const navLinkStyles = ({ isActive }) => ({
    width: '100%',
    backgroundColor: isActive ? '#7071741f' : '',
    color: '#637381',
    transition: 'background-color 0.3s ease',
  });


  const renderSection = (links, isOpen, sectionName, icon, title) => {
    const isActive = isActiveSection(links);

    return (
      <>
        <ListItemButton
          onClick={() => handleToggleSection(sectionName)}
          className={isOpen ? 'list-button' : 'list-button active'}
          sx={{
            width: '100%',
            backgroundColor: isActive ? '#0c35e920' : '',
            color: isActive ? '#3A46D6' : '#637381',
            transition: 'background-color 0.3s ease',
          }}
        >
          <ListItemIcon className="list-button-icon">{icon}</ListItemIcon>
          <ListItemText primary={title} />
          {isOpen ? <MdKeyboardArrowRight size={18} /> : <MdOutlineExpandMore size={18} />}
        </ListItemButton>
        {links.map(({ path, title, iconB }) => (
          <NavLink to={path} className="link" style={navLinkStyles} key={path}>
            <Collapse in={!isOpen} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
              <List component="div" disablePadding>
                <ListItemButton className="list-li" sx={{ py: '4px', width: '100%' }}>
                  <ListItemIcon className="list-li-icon">{iconB}</ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </List>
            </Collapse>
          </NavLink>
        ))}
      </>
    );
  };


  return (
    <>
      <nav className={`nav scrollable ${isCollapsed ? 'collapsed' : ''}`}>
        <div className='nav-list-wrapper'>
          <div className='nav-logo'>
            <img src={isCollapsed ? MobileLogo : Logo} alt='LOGO' />
          </div>
          <hr />
          <div className="nav-list">
            <List sx={{ width: '100%', color: 'white' }} component="ul" className='nav-list-list'>
              {sections.map(({ links, sectionName, icon, title }) => (
                <React.Fragment key={sectionName}>
                  {renderSection(links, openSections[sectionName], sectionName, icon, title)}
                </React.Fragment>
              ))}
            </List>
          </div>
          <ListItemButton sx={{ pl: 4, width: '100%' }} className='navbar-signOut' onClick={handleSignOut}>
            <ListItemIcon className='list-button-icon'><LogoutRoundedIcon /></ListItemIcon>
            <ListItemText primary='Выйти' className='navbar-signOut-text' />
          </ListItemButton>
        </div>
      </nav>
    </>
  );
};
export default NavbarCollapse;



