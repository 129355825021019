import React, { useEffect, useState } from 'react';
import { FormValidation } from '../../../../components/Form/FormValidation/exports';
import {
  citiesRef,
  tariffRef,
  villageRef,
} from '../../../../utils/collectionRefs';
import { Loader } from '../../../../components/Loader/Loader';
import { Header } from '../../../../components/Header/Header';
import { Title } from '../../../../components/Title/Title';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { db } from '../../../../configs';
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import {
  typeOfOrder,
  paymentPerson,
  paymentStatus,
  payment,
} from '../../../../utils';
import { toast } from 'react-toastify';

import {
  TextField,
  Box,
  MenuItem,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Button,
} from '@mui/material';

import {
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  onSnapshot,
  addDoc,
  collection,
} from 'firebase/firestore';
import { Helmet } from 'react-helmet';

const EditOrder = ({ pageTitle }) => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [city, setCity] = useState(null);
  const [district, setDistrict] = useState(null);
  const [district2, setDistrict2] = useState(null);
  const [open, setOpen] = useState(false);
  const [tariff, setTariff] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const navigate = useNavigate();

  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');

  const userCollectionRef = collection(
    db,
    `${roleType}`,
    `${userName}`,
    'history'
  );

  useEffect(() => {
    const fetchOrder = async () => {
      const docRef = doc(db, 'orders', `${id}`);
      const docSnap = await getDoc(docRef);
      setOrder({ ...docSnap.data(), id: docSnap.id });
    };
    fetchOrder();
  }, [id]);

  useEffect(() => {
    const settingTariff = onSnapshot(tariffRef, (snapshot) => {
      setTariff(snapshot.docs.map((doc) => ({ ...doc.data() })));
    });
    return () => settingTariff();
  }, []);

  useEffect(() => {
    const settingCity = onSnapshot(citiesRef, (snapshot) => {
      const cityData = snapshot.docs.map((doc) => ({ ...doc.data() }));
      const sortedCityData = cityData.sort((a, b) => a.id - b.id);
      setCity(sortedCityData);
    });
    return () => settingCity();
  }, []);


  useEffect(() => {
    if (order) {
      const defaultValues = {
        addressFrom: {
          address: order?.addressFrom?.address,
          city: order?.addressFrom?.city || '',
          cityName: order?.addressFrom?.cityName || '',
          district: order?.addressFrom?.district || '',
          districtName: order?.addressFrom?.districtName || '',
          lat: 42.876254,
          lon: 74.604228,
        },
        addressTo: {
          address: order?.addressTo?.address,
          city: order?.addressTo?.city || '',
          cityName: order?.addressTo?.cityName || '',
          district: order?.addressTo?.district || '',
          districtName: order?.addressTo?.districtName || '',
          lat: 42.876254,
          lon: 74.604228,
        },
        tariff: {
          cost: order?.tariff?.cost,
          name: order?.tariff?.name,
          uid: order?.tariff?.uid,
        },
        tariffId: String(order?.tariff?.uid),
        comments: order?.comments,
        cost: Number(order?.cost) ?? Number(order?.tariff?.cost),
        cityFilter: order?.cityFilter,
        cityFrom: order?.addressFrom?.city || '',
        cityTo: order?.addressTo?.city || '',
        packageType: order?.packageType,
        paymentMethod: order?.paymentMethod,
        paymentStatus: order?.paymentStatus,
        receiver: order?.receiver,
        receiverName: order?.receiverName,
        receiverPhone: order?.receiver,
        redemption: Number(order?.redemption),
        sender: order?.sender,
        senderName: order?.senderName,
        senderPhone: order?.sender,
        whoPays: order?.whoPays,
      }

      setInitialValues(defaultValues);
    }
  }, [order]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const paymentMethodOptions = {
    cash: payment[0].value,
    mbank: payment[1].value,
    optima: payment[2].value,
    elsom: payment[3].value,
    odengi: payment[4].value,
    other: payment[5].value,
  };

  const formatValue = (value) => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    return String(value);
  };

  const getChangedFields = (newValues) => {
    const changedFields = {};

    const compareFields = (oldObj, newObj, baseKey) => {
      Object.keys(newObj).forEach((key) => {
        const fullKey = `${baseKey}.${key}`;
        if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])) {
          changedFields[fullKey] = {
            oldValue: formatValue(oldObj[key]),
            newValue: formatValue(newObj[key]),
          };
        }
      });
    };

    Object.keys(newValues).forEach((key) => {
      if (typeof newValues[key] === 'object' && newValues[key] !== null) {
        if (initialValues[key] && typeof initialValues[key] === 'object' && initialValues[key] !== null) {
          compareFields(initialValues[key], newValues[key], key);
        }
      } else {
        if (JSON.stringify(newValues[key]) !== JSON.stringify(initialValues[key])) {
          changedFields[key] = {
            oldValue: formatValue(initialValues[key]),
            newValue: formatValue(newValues[key]),
          };
        }
      }
    });

    return changedFields;
  };
  const fetchDistricts = async (selectedCity, setDistrictFunction) => {
    const id = selectedCity.id;
    const filteredDist = query(villageRef, where('district', '==', id));
    const districtDocs = await getDocs(filteredDist);
    setDistrictFunction(districtDocs?.docs?.map((doc) => ({ ...doc?.data() })));
  };

  const handleUpdateOrder = async (editOrder) => {
    try {
      setOpen(true);
      const fromAddressData = {
        address: editOrder.fromAddress,
        city: editOrder.fromCity?.id ?? null,
        cityName: editOrder.fromCity?.name ?? null,
        district: editOrder.fromDistrict?.id ?? null,
        districtName: editOrder.fromDistrict?.name ?? null,
        lat: 42.876254,
        lon: 74.604228,
      };
      const toAddressData = {
        address: editOrder.toAddress,
        city: editOrder.toCity?.id ?? null,
        cityName: editOrder.toCity?.name ?? null,
        district: editOrder.toDistrict?.id ?? null,
        districtName: editOrder.toDistrict?.name ?? null,
        lat: 42.876254,
        lon: 74.604228,
      };

      const cityFilter =
        order?.status === 'status_at_sorting_center2' || order?.status === 'status_on_courier2'
          ? editOrder.toCity?.id ?? 1
          : editOrder.fromCity?.id ?? 1;

      const updatedData = {
        addressFrom: fromAddressData,
        addressTo: toAddressData,
        tariff: {
          cost: editOrder.tariff.cost,
          name: editOrder.tariff.name,
          uid: String(editOrder.tariff.uid),
        },
        tariffId: String(editOrder.tariff.uid),
        comments: editOrder.commits,
        cost: Number(editOrder.cost) ?? Number(editOrder.tariff.cost),
        cityFilter: cityFilter,
        cityFrom: editOrder.fromCity?.id ?? null,
        cityTo: editOrder.toCity?.id ?? null,
        packageType: editOrder.packageType,
        paymentMethod: editOrder.paymentMethod,
        paymentStatus: editOrder.paymentStatus === 'false' ? false : true,
        receiver: editOrder.toPhone,
        receiverName: editOrder.toName,
        receiverPhone: editOrder.toPhone,
        redemption: Number(editOrder.redemption),
        sender: editOrder.fromPhone,
        senderName: editOrder.fromName,
        senderPhone: editOrder.fromPhone,
        whoPays: Number(editOrder.paymentPerson),
        isEdited: true,
        editedDate: new Date()
      };

      const changedFields = getChangedFields(updatedData);
      const descriptionForHistory = Object.entries(changedFields)
        .map(([key, { oldValue, newValue }]) => `${key}: '${oldValue}' -> '${newValue}'`)
        .join('<br />');
      const fullDescription = `${userType} ${userName} отредактировал детали заказа:<br />${descriptionForHistory}`;

      if (Object.keys(changedFields).length === 0) {
        toast.info('Нет изменений для сохранения');
        setOpen(false)
        return;
      }

      await updateDoc(doc(db, 'orders', `${id}`), updatedData);
      await addDoc(collection(db, 'orders', id, 'history'), {
        date: new Date(),
        description: fullDescription,
      });
      await addDoc(userCollectionRef, {
        date: new Date(),
        description: fullDescription
      })

      setOpen(false);
      navigate('/orders');

      toast.success('Заказ успешно отредактирован', {
        autoClose: 2000,
        hideProgressBar: false,
      });
      reset();
    } catch (e) {
      setOpen(false);
      console.error(e.message);
      toast.error('Произошла ошибка при редактировании заказа', {
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  return (
    <>
      <div className='container'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous={'Список заказов'} initial='Редактирование' />
        <Title
          title={'Редактирование заказа'}
          icon={<NoteAltRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          {!city ? (
            <Loader />
          ) : (
            <form className='order-form'>
              <div className='order-form-flex'>
                {/* ОТПРАВИТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Отправитель</h3>
                  </div>
                  <div className='order-input-block'>
                    {/* <TextField
                        id="outlined-basic"
                        label="Номер телефона"
                        variant="outlined"
                        type="number"
                        helperText={errors?.fromPhone && 'Масимум 10 символов'}
                        error={errors?.fromPhone && true}
                        defaultValue={order?.senderPhone}
                        size="small"
                        {...register('fromPhone', {
                          required: FormValidation.RequiredInput.required,
                        })
                        }
                      /> */}
                    <Controller
                      name='fromPhone'
                      control={control}
                      defaultValue={order?.senderPhone}
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Отправитель'
                          size='small'
                          error={!!errors?.fromPhone}
                          helperText={errors?.fromPhone?.message}
                          {...field}
                        />
                      )}
                    />
                    {/* <div
                      className={
                        !isMenuOpen
                          ? 'testing-client-list'
                          : 'testing-client-list open'
                      }
                    >
                      <ul>{renderMenuItems()}</ul>
                    </div> */}
                    <TextField
                      id='outlined-basic'
                      label={'Имя отправителя'}
                      variant='outlined'
                      helperText={errors?.fromName?.message}
                      defaultValue={order?.senderName}
                      // focused={selectedClient && true}
                      // disabled={selectedClient}
                      error={errors?.fromName && true}
                      size='small'
                      {...register('fromName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <Controller
                      name='fromCity'
                      control={control}
                      defaultValue={{
                        name: order?.addressFrom?.cityName,
                        id: order?.addressFrom?.city,
                      }}
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='from-city'
                          disablePortal
                          options={city || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            field.onChange(val);
                            fetchDistricts(val, setDistrict);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Город/регион'
                              variant='outlined'
                              size='small'
                              error={!!errors?.fromCity}
                              helperText={errors?.fromCity?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name='fromDistrict'
                      control={control}
                      defaultValue={{
                        name: order?.addressFrom?.districtName,
                        id: order?.addressFrom?.district,
                      }}
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-district'
                          disablePortal
                          options={district || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            field.onChange(val);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Район/село'
                              variant='outlined'
                              size='small'
                              error={!!errors?.fromDistrict}
                              helperText={errors?.fromDistrict?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес отправителя'
                      variant='outlined'
                      size='small'
                      error={errors?.fromAddress ? true : false}
                      helperText={errors ? errors?.fromAddress?.message : ''}
                      defaultValue={order?.addressFrom?.address}
                      {...register('fromAddress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ----------------- */}
                {/* ПОЛУЧАТЕЛЬ */}
                <div className='order-block sender-block'>
                  <div className='order-block-head'>
                    <h3>Получатель</h3>
                  </div>
                  <div className='order-input-block'>
                    <Controller
                      name='toPhone'
                      control={control}
                      defaultValue={order?.receiverPhone}
                      rules={{ required: 'Объязательное поле' }}
                      render={({ field }) => (
                        <TextField
                          label='Получатель'
                          size='small'
                          error={!!errors?.toPhone}
                          helperText={errors?.toPhone?.message}
                          // inputRef={inputRef2}
                          {...field}
                        // onChange={(e) => {
                        //   handlePhoneChange2(e.target.value);
                        //   if (e.target.value === '') {
                        //     setValue('toPhone', ''); // Если значение пустое, сбросить его
                        //     setValue('toName', ''); // Если значение пустое, сбросить его
                        //   }
                        //   setValue('toPhone', e.target.value);
                        // }}
                        />
                      )}
                    />
                    {/* <div
                      className={
                        !isMenuOpen2
                          ? 'testing-client-list'
                          : 'testing-client-list open'
                      }
                    >
                      <ul>{renderMenuItems2()}</ul>
                    </div> */}
                    <TextField
                      id='outlined-basic'
                      label={'Имя получателя'}
                      variant='outlined'
                      size='small'
                      helperText={errors?.toName?.message}
                      defaultValue={order?.receiverName}
                      // focused={selectedClient2 && true}
                      // disabled={selectedClient2}
                      error={errors?.toName && true}
                      {...register('toName', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <Controller
                      name='toCity'
                      control={control}
                      defaultValue={{
                        name: order?.addressTo?.cityName,
                        id: order?.addressTo?.city,
                      }}
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-city'
                          disablePortal
                          options={city || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            field.onChange(val);
                            fetchDistricts(val, setDistrict2);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Город/регион'
                              variant='outlined'
                              size='small'
                              error={!!errors?.toCity}
                              helperText={errors?.toCity?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name='toDistrict'
                      control={control}
                      defaultValue={{
                        name: order?.addressTo?.districtName,
                        id: order?.addressTo?.district,
                      }}
                      rules={{
                        required: 'Объязательное поле',
                      }}
                      render={({ field }) => (
                        <Autocomplete
                          id='to-district'
                          disablePortal
                          options={district2 || []}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, val) => {
                            field.onChange(val);
                          }}
                          value={field.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={`random-${Math.random()
                                .toString(36)
                                .substring(7)}`}
                              label='Район/село'
                              variant='outlined'
                              size='small'
                              error={!!errors?.toDistrict}
                              helperText={errors?.toDistrict?.message}
                            />
                          )}
                        />
                      )}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Адрес получателя'
                      variant='outlined'
                      size='small'
                      error={errors?.toAddress && true}
                      helperText={errors?.toAddress?.message}
                      defaultValue={order?.addressTo?.address}
                      {...register('toAddress', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                  </div>
                </div>
                {/* ------------- */}
                {/* ДЕТАЛИ ЗАКАЗА */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Детали заказа</h3>
                  </div>
                  <div className='order-input-block'>
                    <Box className='' sx={{ display: 'flex', gap: '4px' }}>
                      <TextField
                        sx={{ width: '90%' }}
                        id='filled-select-currency'
                        select
                        label='Тип посылки'
                        defaultValue={order?.packageType}
                        variant='outlined'
                        size='small'
                        error={errors?.orderType && true}
                        {...register('packageType', {
                          required: FormValidation.RequiredInput.required,
                        })}
                      >
                        {typeOfOrder.map((type) => (
                          <MenuItem key={type.id} value={type.value}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Controller
                        name='tariff'
                        control={control}
                        defaultValue={order?.tariff}
                        rules={{ required: 'Обязательное поле!' }}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            options={tariff || []}
                            getOptionLabel={(option) =>
                              `${option?.name}(${option?.cost}с)` || ''
                            }
                            style={{ width: '90%' }}
                            onChange={(_, val) => {
                              field.onChange(val);
                              setValue('cost', val?.cost);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Тариф'
                                variant='outlined'
                                className='add-courier-input'
                                size='small'
                                style={{ width: '100%' }}
                                error={!!errors.tariff}
                                helperText={errors?.tariff?.message}
                              />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <TextField
                      type='number'
                      size='small'
                      label='Стоимость доставки'
                      defaultValue={order?.cost ?? getValues('cost')}
                      {...register('cost')}
                    />
                    <TextField
                      id='outlined-basic'
                      label='Выкуп (0 если без выкупа)'
                      variant='outlined'
                      defaultValue={order?.redemption}
                      type='number'
                      size='small'
                      {...register('redemption', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    />
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Кто оплачивает'
                      defaultValue={(() => {
                        switch (order?.whoPays) {
                          case 1:
                            return paymentPerson[0].value;
                          case 2:
                            return paymentPerson[1].value;
                          default:
                            return paymentPerson[2].value;
                        }
                      })()}
                      variant='outlined'
                      size='small'
                      {...register('paymentPerson', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentPerson.map((person) => (
                        <MenuItem key={person.id} value={person.value}>
                          {person.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Метод оплаты'
                      variant='outlined'
                      size='small'
                      defaultValue={paymentMethodOptions[order?.paymentMethod]}
                      {...register('paymentMethod', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {payment.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      id='filled-select-currency'
                      select
                      label='Статус оплаты'
                      defaultValue={`${order?.paymentStatus}`}
                      variant='outlined'
                      size='small'
                      {...register('paymentStatus', {
                        required: FormValidation.RequiredInput.required,
                      })}
                    >
                      {paymentStatus.map((type) => (
                        <MenuItem key={type.id} value={type.value}>
                          {type.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                {/* ДОПОЛНИТЕЛЬНО */}
                <div className='order-block'>
                  <div className='order-block-head'>
                    <h3>Дополнительно</h3>
                  </div>
                  <div className='order-input-block'>
                    <TextField
                      id='outlined-multiline-static'
                      label='Коментарии'
                      multiline
                      rows={4}
                      defaultValue={order?.comments}
                      {...register('commits')}
                    />
                  </div>
                </div>
              </div>
              <Button
                onClick={handleSubmit((data) => handleUpdateOrder(data))}
                size='large'
                variant='contained'
                style={{ background: '#000b1f' }}
              >
                Сохранить
              </Button>
            </form>
          )}
        </div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    </>
  );
};

export default EditOrder;


// function generateForSearch(phone) {
//   return Array.from(phone)?.reduce((acc, digit, index) => {
//     if (index === 0) {
//       acc.push(digit);
//     } else {
//       acc.push(acc[index - 1] + digit);
//     }
//     return acc;
//   }, []);
// }

// const inputRef = useRef(null);
// const inputRef2 = useRef(null);
// const [clients, setClients] = useState([]);
// const [clients2, setClients2] = useState([]);
// const [isMenuOpen, setMenuOpen] = React.useState(false);
// const [isMenuOpen2, setMenuOpen2] = React.useState(false);
// const [selectedClient, setSelectedClient] = useState(null);
// const [selectedClient2, setSelectedClient2] = useState(null);
// const searchClientInFirebase = async (phoneSubstring) => {
//   const q = query(
//     clientsRef,
//     where('forSearch', 'array-contains', phoneSubstring)
//   );
//   const querySnapshot = await getDocs(q);
//   const matchingClients = querySnapshot.docs.map((doc) => doc.data());
//   return matchingClients;
// };

// const handlePhoneChange = debounce(async (phone) => {
//   const matchingClients = await searchClientInFirebase(phone);
//   setClients(matchingClients);
//   setMenuOpen(matchingClients.length > 0);
// }, 500);

// const handlePhoneChange2 = debounce(async (phone) => {
//   const matchingClients = await searchClientInFirebase(phone);
//   setClients2(matchingClients);
//   setMenuOpen2(matchingClients.length > 0);
// }, 500);

// function handleMenuItemClick(event, data) {
//   setSelectedClient(data);
//   setMenuOpen(false);
//   setValue('fromName', data.fullName);
//   setValue('fromPhone', data.phone);
// }
// function handleMenuItemClick2(event, data) {
//   setSelectedClient2(data);
//   setMenuOpen2(false);
//   setValue('toName', data.fullName);
//   setValue('toPhone', data.phone);
// }

// const renderMenuItems = useCallback(() => {
//   return clients?.map((item) => (
//     <li
//       key={item?.phone}
//       onClick={(event) => handleMenuItemClick(event, item)}
//     >
//       <p>
//         {item?.fullName} ({item?.phone})
//       </p>
//     </li>
//   ));
// });

// const renderMenuItems2 = useCallback(() => {
//   return clients2?.map((item) => (
//     <li
//       key={item?.phone}
//       onClick={(event) => handleMenuItemClick2(event, item)}
//     >
//       <p>
//         {item?.fullName} ({item?.phone})
//       </p>
//     </li>
//   ));
// });



// const senderClients = await searchClientInFirebase(editOrder.fromPhone);
// const receiverClients = await searchClientInFirebase(editOrder.toPhone);
// const senderClientExists = senderClients.some(
//   (client) => client.phone === editOrder.fromPhone
// );
// const receiverClientExists = receiverClients.some(
//   (client) => client.phone === editOrder.toPhone
// );


// if (!senderClientExists) {
//   const clientData = {
//     address: {
//       address: order.addressFrom || editOrder?.addressFrom?.address,
//       city: order.fromCity?.id ?? editOrder?.fromCity?.id,
//       cityName: order.fromCity?.name ?? editOrder?.fromCity?.name,
//       district: order?.fromDistrict?.id ?? editOrder?.fromDistrict?.id,
//       districtName: order?.fromDistrict?.name ?? editOrder?.fromDistrict?.name,
//       lat: 0,
//       lon: 0,
//     },
//     avatar: '',
//     blackList: false,
//     comments: '',
//     forSearch: generateForSearch(order?.fromPhone || editOrder?.fromPhone),
//     fullName: order.fromName || editOrder?.fromName,
//     orders: 0,
//     phone: order.fromPhone || editOrder?.fromPhone,
//     uid: order.fromPhone || editOrder?.fromPhone,
//   };
//   console.log('SENDER', clientData)
//   await setDoc(doc(db, 'clients', `${clientData?.phone}`), clientData);
//   toast.success('Новый клиент успешно добавлен!', {
//     autoClose: 2000,
//     hideProgressBar: false,
//   });
// }
// if (!receiverClientExists) {
//   const clientData = {
//     address: {
//       address: order.addressTo || editOrder?.toAdress?.address,
//       city: order.toCity?.id ?? editOrder?.toCity?.id,
//       cityName: order.toCity?.name ?? editOrder?.toCity?.name,
//       district: order?.toDistrict?.id ?? editOrder?.toDistrict?.id,
//       districtName: order?.toDistrict?.name ?? editOrder?.toDistrict?.name,
//       lat: 0,
//       lon: 0,
//     },
//     avatar: '',
//     blackList: false,
//     comments: '',
//     forSearch: generateForSearch(order.toPhone || editOrder?.toPhone),
//     fullName: order.toName || editOrder?.toName,
//     orders: 0,
//     phone: order.toPhone || editOrder?.toPhone,
//     uid: order.toPhone || editOrder?.toPhone,
//   };
//   console.log("RECEIVER", editOrder)
//   await setDoc(doc(db, 'clients', `${clientData?.phone}`), clientData);
//   toast.success('Новый клиент успешно добавлен!', {
//     autoClose: 2000,
//     hideProgressBar: false,
//   });
// }