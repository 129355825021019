import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { doc, where, getDocs, query, orderBy, getCountFromServer, Timestamp, getAggregateFromServer, sum, onSnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { Loader } from '../../../../components/Loader/Loader';
import { Title } from '../../../../components/Title/Title';
import { handleSignOut } from '../../../../configs';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, Rating, MenuItem, Stack, TextField, ToggleButton, ToggleButtonGroup, } from '@mui/material';
import { Box } from '@mui/system';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { completedOrdersRef, couriersNewRef, orderRef, returnedOrderRef } from '../../../../utils/collectionRefs';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import OrderGrid from '../../../../components/OrderGrid ';
import './scss/CourierDetail.scss';
import { Helmet } from 'react-helmet';
import { courierTransactionColumns } from '../Transaction/helpers/transactionsColumn';
import { countOfOrdersCard, courirerTransactionMinusLabels, courirerTransactionPlusLabels } from './constants';
import { fieldsToSearchOrder, headers, headersForCompletedOrders } from '../Orders/constants';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import OrderSort from '../../../../components/OrderSort';
import { transactionExpenseLabels, typeOfTransactionExpenseLables } from '../Transaction/constants';
import { returnedOrderStatusLabels } from '../Orders/constants';
import ExportToExcel from '../../../../components/Buttons/ExportToExcell';
import { orderColumns } from '../Orders/helpers/orderColumns';
import { handleCopyOrder } from '../Orders/helpers/handleCopyOrder';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import { handleChangeStatus } from '../Orders/helpers/handleChangeStatus';
import { returnedOrderAlternativeStatusLabels } from './../Orders/constants/index';
import { completedOrderExcelDataFormatter, orderExcelDataFormatter } from '../Orders/helpers/excelDataFormatter';
import { getDayAgo, getEndOfDay } from '../../../../utils/dateFormatter';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import { useKassa } from '../Kassa/helpers/useKassa';
import axios from 'axios';
import { getAuthToken, verifyToken } from '../../../Auth/Login/authToken';
import axiosInstance from './../../../../configs/axiosInstance';
import Pko from '../../../../components/Form/Pko';
import Rko from '../../../../components/Form/Rko';
import avatar from '../../../../assets/images/avatar.svg';

const CouriersTest2Detail = ({ pageTitle }) => {
  const { id } = useParams();
  const [orders, setOrders] = useState([]);
  const [courier, setCourier] = useState(null);
  const [transActions, setTransActions] = useState(null);
  const { kassa } = useKassa();

  const [pageSize, setPageSize] = useState(20)
  const [page, setPage] = useState(1)
  const [isLoadingTransActions, setIsLoadingTransActions] = useState(true);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [showTransactions, setShowTransactions] = useState(true);
  const [totalCount, setTotalCount] = useState({ active: 0, delivered: 0 })
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [operation, setOperation] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({ type: '' });
  const [aggregates, setAggregates] = useState({ cost: 0, redemption: 0 })
  const [activeButton, setActiveButton] = useState('transactions');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [search, setSearch] = useState('');
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [transFormData, setTransFormData] = useState(null)
  const [transactionType, setTransactionType] = useState(null);
  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleDateChange2 = (e) => setSelectedDate2(e.target.value);

  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const roleType = localStorage.getItem('roleType');

  const navigate = useNavigate();

  const todayStart = getDayAgo(0);
  const todayEnd = getEndOfDay(0);

  const startTimestamp = Timestamp.fromDate(todayStart);
  const endTimestamp = Timestamp.fromDate(todayEnd);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors }
  } = useForm({ mode: 'onChange' });

  const typeOfСonsumption = watch('transactionType')

  useEffect(() => {
    const checkTokenAndRedirect = async () => {
      try {
        await verifyToken();
      } catch (error) {
        console.error('Ошибка при проверке токена:', error);
        handleSignOut()
      }
    };
    checkTokenAndRedirect();
  }, [id]);

  useEffect(() => {
    if (typeOfСonsumption === 'admin_minus') {
      setValue('kassa', '', { shouldValidate: true });
    } else {
      setValue('kassa', '');
    }
  }, [typeOfСonsumption, setValue]);

  const handleButtonGroupChange = (event, newType) => {
    if (newType !== null) {
      setActiveButton(newType);

      switch (newType) {
        case 'transactions':
          handleTransactionsClick();
          break;
        case 'activeToday':
          fetchOrdersForToday(activeOrdersQueryForToday, 'orders');
          break;
        case 'active':
          fetchOrdersForToday(activeOrdersQuery, 'orders');
          break;
        case 'completed':
          fetchOrdersForToday(deliveredOrdersQuery, 'completed');
          break;
        case 'completedToday':
          fetchOrdersForToday(deliveredOrdersQueryForToday, 'completed');
          break;
        case 'returnedToday':
          fetchOrdersForToday(returnedOrdersQueryForToday, 'returned');
          break;
        case 'returned':
          handleReturnedOrdersClick();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const getCourier = () => {
      const docRef = doc(couriersNewRef, id);

      const unsubscribe = onSnapshot(docRef, async (docSnap) => {
        if (docSnap.exists()) {
          const courierData = docSnap.data();

          setCourier({ ...courierData, id: docSnap.id });
        } else {
          console.log("Document doesn't exist");
        }
      });

      return () => {
        unsubscribe();
      };
    };

    getCourier();
  }, [id]);

  useEffect(() => {
    if (open) {
      if (operation === 'add') {
        setValue('transactionType', 'admin_plus');
      } else if (operation === 'subtract') {
        setValue('transactionType', 'admin_minus');
      }
    }
  }, [open, operation, setValue]);

  const fetchCountOfOrders = useCallback(async () => {
    try {
      const activeTypeOfQuery = query(
        orderRef,
        where('couriersForFilter', 'array-contains', id)
      )

      const completedOrdersQuery = query(
        completedOrdersRef,
        where('couriersForFilter', 'array-contains', id)
      );

      const returnedTypeOfQuery = query(
        returnedOrderRef,
        where('lastCourier', '==', id),
        where('status', '==', 'status_rejected_courier'),
      )

      const activeOrdersSnapshot = await getCountFromServer(activeTypeOfQuery);
      const completedOrdersSnapshot = await getCountFromServer(completedOrdersQuery);
      const returnedOrdersSnapshot = await getCountFromServer(returnedTypeOfQuery);

      setTotalCount({
        active: activeOrdersSnapshot.data().count,
        delivered: completedOrdersSnapshot.data().count,
        returned: returnedOrdersSnapshot.data().count,
      });

    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  }, [id]);

  const fetchOrdersForToday = async (query, type) => {
    setIsLoadingOrders(true)
    setShowTransactions(false);
    setTransActions([])
    try {
      const aggregation = await getAggregateFromServer(query, {
        sumCost: sum('cost'),
        sumRedemption: sum('redemption'),
      });

      const totalCost = aggregation.data().sumCost || 0;
      const totalRedemption = aggregation.data().sumRedemption || 0;

      const querySnapshot = await getDocs(query);
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      setAggregates({ cost: totalCost, redemption: totalRedemption });
      setOrders({ data, type });
      setIsLoadingOrders(false);
    } catch (error) {
      console.error('Ошибка при получении заказов:', error);
      setIsLoadingOrders(false);
    }
  };

  const fetchTransactions = useCallback(async () => {
    setIsLoadingTransActions(true);
    setOrders([]);
    try {
      const response = await axiosInstance.get('/user/courierTransaction/', {
        params: {
          page,
          courierPhone: id,
          dateFrom: selectedDate,
          dateTo: selectedDate2,
          type: selectedFilters.type,
        }
      });
      setTransActions(response.data.results);
      setTotalRowCount(response.data.count);
      setIsLoadingTransActions(false);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setIsLoadingTransActions(false);
    }
  }, [id, selectedFilters, selectedDate, selectedDate2, page])

  const ferchReturnedOrders = async () => {
    setIsLoadingOrders(true);
    setTransActions([]);
    try {
      const rejectedOrdersQuery = query(
        returnedOrderRef,
        orderBy('dateCreated', 'desc'),
        where('lastCourier', '==', id),
        where('status', '==', 'status_rejected_courier')
      );
      const aggregation = await getAggregateFromServer(rejectedOrdersQuery, {
        sumCost: sum('cost'),
        sumRedemption: sum('redemption'),
      });
      const totalCost = aggregation.data().sumCost || 0;
      const totalRedemption = aggregation.data().sumRedemption || 0;

      const querySnapshot = await getDocs(rejectedOrdersQuery);
      const rejectedOrders = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));

      setAggregates({ cost: totalCost, redemption: totalRedemption });
      setOrders({ data: rejectedOrders, type: 'returned' });
      setIsLoadingOrders(false);
    } catch (error) {
      console.error('Error fetching rejected orders:', error);
      setIsLoadingOrders(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    fetchCountOfOrders()
  }, [fetchCountOfOrders])

  // const handleDeleteCourier = async () => {
  //   try {
  //     await updateDoc(doc(couriersNewRef, id), { active: false });
  //     toast.success('Курьер успешно был удален', {
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //     });
  //     navigate('/couriersTest2')
  //   } catch (error) {
  //     toast.error('Произошла ошибка при удалении курьера:', {
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //     });
  //     console.error('Error deleting courier:', error);
  //   } finally {
  //     setOpenDeleteModal(false)
  //   }
  // };

  const handleOpen = (op) => {
    setOperation(op);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmitTransaction = async (data, type) => {
    let loadingToastId;
    const newAmount = parseInt(data.amount) * (operation === 'subtract' ? -1 : 1);

    const transactionData = {
      type: data.transactionType,
      orderUid: '-',
      summa: newAmount,
      balanceBefore: 0,
      balanceAfter: 0,
      author: userName,
      comment: data.comment ?? '',
      courierPhone: id,
    };
    try {
      handleClose()
      loadingToastId = toast.loading('Добавление транзакции...', { autoClose: false });
      await axiosInstance.post('user/courierTransaction/', transactionData);
      toast.success('Транзакция успешно добавлена');
      toast.dismiss(loadingToastId);
      setTransFormData(() => ({
        ...transactionData,
        acceptedFrom: `${courier?.name} ${courier?.surName}`,
        basics: transactionExpenseLabels[data.transactionType],
        passport: courier?.passport ?? null,
      }))

      setTransactionType(type);
      setIsResultModalOpen(true);
      console.log(type)
      if (data.kassa) {
        const newAmount = parseInt(data.amount) * (operation === 'add' ? -1 : 1);
        const newBalanceAfter = (data.kassa.balance || 0) + newAmount;
        const type = newAmount < 0 ? 2 : 4
        const token = await getAuthToken();

        const descriptionType = operation === 'add'
          ? `Депозит курьеру ${courier.name} ${courier.phone} | сумма ${parseInt(newAmount)}`
          : `Курьер: ${courier.name} ${courier.phone}`

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const kassaTransactionData = {
          balanceBefore: data.kassa?.balance || 0,
          balanceAfter: newBalanceAfter,
          summa: parseInt(newAmount),
          comment: `${data.comment} | ${descriptionType} | Автор: ${roleType} ${userName}` ?? '',
          type: type,
          author: `${roleType} ${userName}`,
          dateCreated: new Date(),
          kassa: data.kassa.id,
        };

        try {
          loadingToastId = toast.loading('Добавление транзакции кассы', { autoClose: false });
          await axios.post('https://aylex.ru/api/core/kassaTransaction/', kassaTransactionData, { headers });
          toast.success('Транзакция кассы успешно добавлена');
          toast.dismiss(loadingToastId);

        } catch (error) {
          toast.dismiss(loadingToastId);
          console.error("Ошибка при добавлении транзакции в кассу:", error);
          toast.error("Ошибка при добавлении транзакции в кассу");
        }
      }

      await fetchTransactions()
    } catch (error) {
      console.error('Error adding transaction: ', error);
      handleClose()
      toast.dismiss(loadingToastId);
      setIsResultModalOpen(false);
      setTransFormData(null)

    }
  };

  const handleButtonClick = () => {
    window.open(`/search?courierId=${id}`, '_blank');
  };

  const createOrderSortHandler = (field) => (event) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [field]: event.target.value,
    }));
  }

  const handlePageChange = (newPage) => {
    setPage(newPage + 1);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const handleStatusChange = async (id, newStatus) => {
    await handleChangeStatus({
      orders: orders?.data,
      id,
      newStatus,
      dataRef: orders?.type === '',
      returnedOrderStatusLabels,
      returnedOrderAlternativeStatusLabels,
    });
  };
  const refType = (() => {
    switch (orders.type) {
      case 'orders': return orderRef;
      case 'completed': return completedOrdersRef;
      default: return returnedOrderRef;
    }
  })();

  const textForExportExcell = (() => {
    switch (orders.type) {
      case 'orders': return 'Список активных заказов курьера';
      case 'completed': return 'Список доставленных заказов курьера';
      default: return 'Список возвратов заказов курьера';
    }
  })();

  const headersForExportExcell = (() => {
    switch (orders.type) {
      case 'orders': return headers;
      default: return headersForCompletedOrders;
    }
  })();

  const dataForamtterForExportExcell = (() => {
    switch (orders.type) {
      case 'orders': return orderExcelDataFormatter;
      default: return completedOrderExcelDataFormatter;
    }
  })();

  const selectedFiltersForOrders = (() => {
    switch (orders.type) {
      case 'orders':
        return { lastCourier: id };
      case 'completed':
        return { lastCourier: id };
      default:
        return {
          lastCourier: id,
          status: 'status_rejected_courier'
        };
    }
  })();

  const handleTransactionsClick = () => {
    setShowTransactions(true);
    fetchTransactions();
  };

  const handleReturnedOrdersClick = () => {
    setShowTransactions(false);
    ferchReturnedOrders();
  };

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredData = useMemo(() => {
    if (!orders.data) return { filteredOrders: [], aggregates: { count: 0, totalCost: 0, totalRedemption: 0 } };
    const filteredOrders = orders?.data?.filter((order) =>
      fieldsToSearchOrder.some((field) => {
        const value = order[field];
        return value && value.toLowerCase().includes(search.toLowerCase());
      })
    );

    const aggregates = {
      count: filteredOrders.length,
      totalCost: filteredOrders.reduce((sum, order) => sum + (order.cost || 0), 0),
      totalRedemption: filteredOrders.reduce((sum, order) => sum + (order.redemption || 0), 0),
    };

    return { filteredOrders, aggregates };
  }, [orders.data, search]);

  const transactionTypeSort = createOrderSortHandler('type');

  const column = courierTransactionColumns({ path: `/transaction/update` })
  const countOfOrder = countOfOrdersCard(totalCount.active, totalCount.delivered, totalCount.returned)

  const handleExport = async () => {
    try {
      const { data } = await axiosInstance.get(`/user/export/courier-transactions/${id}/`);

      if (data?.file) {
        const link = document.createElement('a');
        link.href = data.file;
        link.download = data.title || 'exported_file.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Файл не найден в ответе');
      }
    } catch (error) {
      console.error('Ошибка при экспорте:', error);
    }
  };

  const returnedOrdersQueryForToday = query(
    returnedOrderRef,
    orderBy('dateDelivered', 'desc'),
    where('lastCourier', '==', id),
    where('status', '==', 'status_rejected_courier'),
    where('dateDelivered', '>=', startTimestamp),
    where('dateDelivered', '<=', endTimestamp)
  );
  const activeOrdersQueryForToday = query(
    orderRef,
    orderBy('dateCreated', 'desc'),
    where('lastCourier', '==', id),
    where('dateCreated', '>=', startTimestamp),
    where('dateCreated', '<=', endTimestamp)
  );
  const activeOrdersQuery = query(
    orderRef,
    orderBy('dateCreated', 'desc'),
    where('lastCourier', '==', id),
  );
  const deliveredOrdersQueryForToday = query(
    completedOrdersRef,
    orderBy('dateDelivered', 'desc'),
    where('lastCourier', '==', id),
    where('dateDelivered', '>=', startTimestamp),
    where('dateDelivered', '<=', endTimestamp)
  );
  const deliveredOrdersQuery = query(
    completedOrdersRef,
    orderBy('dateDelivered', 'desc'),
    where('lastCourier', '==', id),
  );

  const actionsConfig = [
    {
      title: 'Копировать',
      icon: <FileCopyRoundedIcon style={{ color: '#000b1f' }} />,
      handler: handleCopyOrder,
    },
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];
  const columns = orderColumns({
    actionsConfig: actionsConfig,
    path: `/order${orders?.type !== 'orders' ? '/' : ''}${orders?.type === 'orders' ? '' : orders?.type}/details`,
    editable: true,
    customLabels: orders?.type === 'returned' ? returnedOrderStatusLabels : '',
    orderType: `${orders?.type === 'orders' ? '' : 'completed'}`
  });


  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header previous='Список курьеров' initial='Детали курьера' />
      <Title title='Детали курьера' icon={<AssignmentIndRoundedIcon fontSize='large' />} />
      <div className='container-inner'>
        <div className='courier-more-wrapper'>
          {!courier ? (
            <Loader />
          ) : (
            <div className='courier-more-card'>
              <div className='courier-card-left-side'>
                <div className='courier-card-avatar'>
                  <img
                    src={!courier?.avatar ? avatar : courier?.avatar}
                    alt={courier?.name}
                  />
                  <span className={!courier?.online ? 'courier-card-status offline' : 'courier-card-status online'}
                  ></span>
                </div>
                <h3>
                  {' '}
                  {courier?.name} {courier?.surName}
                </h3>
              </div>
              <div className='courier-card-right-side'>
                <div className='courier-card-list'>
                  <span>
                    <p>Имя:</p>
                    <p>{courier?.name}</p>
                  </span>
                  <span>
                    <p>Фамилия:</p>
                    <p>{courier?.surName}</p>
                  </span>
                  <span>
                    <p>Номер:</p>
                    <p>{courier?.phone}</p>
                  </span>
                </div>
                <div className='courier-card-list'>
                  <span style={{ width: '30%' }}>
                    <p>Рейтинг:</p>
                    <Rating
                      name='rating'
                      defaultValue={parseFloat(courier?.raiting)}
                      precision={0.5}
                      readOnly
                    />
                  </span>
                  <span>
                    <p>Город:</p>
                    <p>{courier?.cityInfo?.name}</p>
                  </span>
                  <span>
                    <p>Аккаунт:</p>
                    <p>{!courier?.active ? 'Не активный' : 'Активный'}</p>
                  </span>
                </div>
                <div className='courier-card-list'>
                  <span style={{ width: '100%' }}>
                    <p>Тип курьера:</p>
                    <p>{courier?.type}</p>
                  </span>
                  {courier?.dolya && (
                    <span style={{ width: '100%' }}>
                      <p>Доля:</p>
                      <p>{courier?.dolya}</p>
                    </span>
                  )}
                </div>
                <div className='courier-card-list'>
                  <Box display='flex' gap='7px' className='courier-button-list'>
                    {/* {userType === 'Админ' && (
                      <>
                        <Button
                          variant='black-contained'
                          onClick={() => navigate(`/courier/update/${courier?.courier?.phone}`)}
                        >
                          <EditRoundedIcon />
                        </Button>
                        <Button
                          variant='black-contained'
                          onClick={() => setOpenDeleteModal(true)}
                        >
                          <DeleteRoundedIcon />
                        </Button>
                      </>
                    )}
                    <Divider orientation='vertical' flexItem /> */}

                    <Button variant='black-contained' onClick={handleButtonClick}>
                      Заказы
                    </Button>
                  </Box>
                </div>
              </div>
            </div>

          )}
          <div className='courier-avatar-card'>
            <div className='courier-balance-card courier-balance-card__two'>
              <div className='courier-balance-card-title'>
                <MonetizationOnRoundedIcon style={{ color: '#10B981' }} />
                <p>Balance</p>
              </div>
              <div className='courier-balance-card-data'>
                <p>{(courier?.balance ?? 0).toLocaleString()}с</p>
              </div>
              <div className='courier-balance-btn-container'>
                <Button
                  variant='contained'
                  size='md'
                  className='courier-more-btn'
                  onClick={() => handleOpen('add')}
                  disabled={!courier}
                >
                  <AddRoundedIcon />
                </Button>
                <Button
                  variant='contained'
                  size='md'
                  className='courier-more-btn'
                  onClick={() => handleOpen('subtract')}
                  disabled={!courier}
                >
                  <RemoveRoundedIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Stack direction='row' gap={2} mt={2.5}>
          {countOfOrder?.map((item, index) => (
            <BalanceCard
              key={index}
              value={item?.value}
              title={item.title}
              icon={NumbersRoundedIcon}
              iconColor={item.borderColor}
              borderColor={item.borderColor}
            />

          ))}

        </Stack>
        <Box bgcolor='#fff' boxShadow={'0 6px 6px rgb(194 202 255 / 30%)'} padding={'1rem'} mt={2.5} borderRadius={'12px'}>
          <Stack direction='row' gap={2}>
            <ToggleButtonGroup
              value={activeButton}
              exclusive
              onChange={handleButtonGroupChange}
              color="primary"
            >
              <ToggleButton value="transactions">Транзакции</ToggleButton>
              <ToggleButton value="activeToday">Активные за сегодня</ToggleButton>
              <ToggleButton value="active">Активные</ToggleButton>
              <ToggleButton value="completedToday" color='success'>Завершенные за сегодня</ToggleButton>
              <ToggleButton value="completed" color='success'>Завершенные</ToggleButton>
              <ToggleButton value="returnedToday" color='warning'>Возвраты за сегодня</ToggleButton>
              <ToggleButton value="returned" color='warning'>Возвраты</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Stack direction="row" gap={2} mt={2.5}>
            <BalanceCard
              title="Общая сумма заказов"
              value={aggregates.cost || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor="#10B981"
              borderColor="#10B981"
            />
            <BalanceCard
              title="Общая сумма выкупов"
              value={aggregates.redemption || 0}
              icon={MonetizationOnRoundedIcon}
              iconColor="#635BFF"
            />
          </Stack>
        </Box>
        <div className='orders-card-wrapper' style={{ background: 'white', marginTop: '20px' }}>
          <div className='orders-card-header'>
            {!showTransactions && !isLoadingTransActions ? (
              <div className='courier-detail__btns'>
                <Stack direction='row' gap={2} sx={{ width: '100%' }} alignItems='center'>
                  <ExportToExcel
                    firestoreRef={refType}
                    selectedFilters={selectedFiltersForOrders}
                    headers={headersForExportExcell}
                    selectedDate={todayStart}
                    selectedDate2={todayEnd}
                    dateField={orders?.type === 'orders' ? 'dateCreated' : 'dateDelivered'}
                    fileName={textForExportExcell}
                    dataFormatter={dataForamtterForExportExcell}
                  />
                  <div style={{ width: '41%' }}>
                    <SearchInput
                      label='Поиск'
                      placeholder='Введите'
                      variant='outlined'
                      onChange={handleSearchOrder}
                    />
                  </div>
                </Stack>

              </div>
            ) : (

              <div className="courier-detail__btns">
                <Button variant='black-contained' onClick={handleExport}>
                  Экспорт
                </Button>
                <Stack direction='row' gap={2} sx={{ width: '100%' }} alignItems='center'>
                  <Box width={250}>
                    <OrderSort
                      sortKey='Сорт. по типу расхода'
                      defaultValue='Все'
                      options={typeOfTransactionExpenseLables}
                      onChange={transactionTypeSort}
                      sx={{ margin: 0, width: '100%' }}
                    />
                  </Box>
                  <TextField
                    type='date'
                    size='small'
                    focused
                    color='warning'
                    value={selectedDate || ''}
                    onChange={handleDateChange}
                    label='Начало'
                  />
                  <TextField
                    type='date'
                    size='small'
                    focused
                    color='warning'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                    label='Конец'
                  />

                </Stack>
              </div>
            )}
          </div>
          {isLoadingOrders && <Loader />}
          {isLoadingTransActions && <Loader />}
          {showTransactions && !isLoadingOrders && (
            <OrderGrid
              orders={transActions}
              columns={column}
              size={pageSize}
              page={page - 1}
              loading={isLoadingTransActions}
              totalRowCount={totalRowCount}
              usePagination={true}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
          {!showTransactions && !isLoadingTransActions && (
            <OrderGrid
              orders={filteredData?.filteredOrders || orders?.data}
              columns={columns}
              size={20}
              handleChangeStatus={handleStatusChange}
            />
          )}
        </div>
      </div>

      <Dialog open={open} onClose={handleClose} maxWidth='xs'>
        <DialogTitle>
          Изменение баланса {operation === 'subtract' ? '(минус баланс)' : '(плюс баланс)'}
        </DialogTitle>
        <form>
          <DialogContent>
            <Controller
              name='amount'
              control={control}
              defaultValue=''
              rules={{ required: true, min: 1 }}
              render={({ field }) => (
                <TextField
                  autoFocus
                  margin='dense'
                  id='amount'
                  label='Сумма'
                  type='number'
                  fullWidth
                  error={!!errors.amount}
                  {...field}
                />
              )}
            />
            <Controller
              name='comment'
              control={control}
              defaultValue=''
              render={({ field }) => (
                <TextField
                  margin='dense'
                  id='comment'
                  label='Комментарий'
                  fullWidth
                  {...field}
                />
              )}
            />
            <Controller
              name="transactionType"
              control={control}
              defaultValue={operation === "add" ? "admin_plus" : "admin_minus"}
              render={({ field }) => {
                const options = operation === "add" ? courirerTransactionPlusLabels : courirerTransactionMinusLabels;

                return (
                  <Autocomplete
                    {...field}
                    options={options}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return options.find((item) => item.value === option)?.name || option;
                      }
                      return option?.name || "";
                    }}
                    isOptionEqualToValue={(option, value) => option.value === value}
                    value={options.find((item) => item.value === field.value) || null}
                    onChange={(_, newValue) => field.onChange(newValue ? newValue.value : "")}
                    renderInput={(params) => (
                      <TextField {...params} label="Тип операции" margin="dense" fullWidth />
                    )}
                  />
                );
              }}
            />

            <Controller
              name='kassa'
              control={control}
              defaultValue=''
              rules={typeOfСonsumption === 'admin_minus' || typeOfСonsumption === 'deposit' ? { required: true } : { required: false }}
              render={({ field }) => (
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Выберите кассу'
                  fullWidth
                  {...field}
                  error={typeOfСonsumption === 'admin_minus' || typeOfСonsumption === 'deposit'}
                  helperText={typeOfСonsumption === 'admin_minus' || typeOfСonsumption === 'deposit' ? 'Обязательное поле!' : ''}
                  value={field.value.id || ''}
                  onChange={(e) => {
                    const selectedOption = kassa.find(option => option.id === e.target.value);
                    field.onChange(selectedOption);
                  }}
                >
                  {kassa?.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{ color: '#000b1f' }} variant='text'>
              Отмена
            </Button>
            {operation === 'subtract' ? (
              <>
                <Button
                  variant="text"
                  sx={{ color: '#000b1f' }}
                  onClick={handleSubmit((data) => onSubmitTransaction(data, "RKO"))}
                >
                  Расход
                </Button>
                <Button
                  variant="text"
                  sx={{ color: '#000b1f' }}
                  onClick={handleSubmit((data) => onSubmitTransaction(data, "PKO"))}
                >
                  Приход
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                variant="text"
                sx={{ color: '#000b1f' }}
                onClick={handleSubmit((data) => onSubmitTransaction(data, "PKO"))}
              >
                Добавить
              </Button>
            )}

          </DialogActions>
        </form>
      </Dialog>
      <Dialog open={isResultModalOpen} onClose={() => setIsResultModalOpen(false)} maxWidth="xl">
        <DialogTitle>
          {
            operation === "add"
              ? "Приходный кассовый ордер (ПКО)"
              : transactionType === "RKO" ? "Расходный кассовый ордер (РКО)" : "Приходный кассовый ордер (ПКО)"
          }
        </DialogTitle>
        <DialogContent>
          {
            operation === "add"
              ? <Rko formData={transFormData} />
              : transactionType === "RKO" ? <Rko formData={transFormData} /> : <Pko formData={transFormData} />
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResultModalOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      {/* <CustomDialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        event={() => handleDeleteCourier()}
        text='Вы уверены что хотите удалить курьера?'
        btnText='Удалить'
      /> */}
    </div>
  );
};

export default CouriersTest2Detail;
