import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import admin from '../../../assets/images/admin.svg';
import dispatcher from '../../../assets/images/dispatcher.svg';
import accountant from '../../../assets/images/accountant.svg';
import request from '../../../assets/images/request.svg';
import courier from '../../../assets/images/courier-delivery.svg';
import './Auth.css';
import { adminRef, managerRef, accountantRef, couriersRef } from '../../../utils/collectionRefs';

export const roles = [
  { id: 1, name: 'Админ',engName: 'admin' ,photo: admin, ref: adminRef, field: 'login' },
  { id: 2, name: 'Менеджер',engName: 'manager' ,photo: dispatcher, ref: managerRef, field: 'login' },
  { id: 3, name: 'Бухгалтер',engName: 'accountant' ,photo: accountant, ref: accountantRef, field: 'login' },
  { id: 4, name: 'Курьер',engName: 'couriers' ,photo: courier, ref: couriersRef, field: 'phone' },
];
const SelectRole = () => {

  return (
    <>
      <div className='role-container'>
        <div className='role-container-logo'>
          <img src={logo} alt='Aylex Logo' />
        </div>
        <h2>Для входа выберите тип аккаунта</h2>
        <div className='role-cards-block'>
          {roles?.map((role) => (
            <NavLink
              to='/auth/login'
              state={{ from: role?.name }}
              className='role-cards'
              key={role.id}
            >
              <img src={role.photo} alt='*' />
              <h2>{role.name}</h2>
            </NavLink>
          ))}
        </div>
        <div className='role-cards-block'>
          <NavLink
            to='/auth/deleteRequest'
            state={{ from: 'Удаленный запрос' }}
            className='role-cards'
          >
            <img src={request} alt='*' />
            <h2>Удаленный запрос</h2>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default SelectRole;
