import React from "react";
import { Link } from "react-router-dom";
import {
  Rating,
  Tooltip,
  IconButton,
} from '@mui/material';
import { RemoveRedEyeRounded } from '@mui/icons-material';

const ActionIconButton = ({ title, icon, onClick }) => (
  <Tooltip title={title} arrow>
    <IconButton color='primary' aria-label='edit' onClick={onClick}>
      {React.cloneElement(icon, { style: { color: '#000b1f' } })}
    </IconButton>
  </Tooltip>
);


export const getCourierColumn = ({ actionsConfig, path }) => {
  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        {!actionsConfig ? null : (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`${path}/${params.id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRounded style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )}
      </div>
    ),
    renderHeader: () => <strong>{!actionsConfig ? '' : 'Действия'}</strong>,
  };
  return [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`${path}/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRounded style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip >
      )
    },
    {
      flex: 1,
      headerName: 'ID',
      field: 'id',
      sortable: true,
      minWidth: 130,
    },
    {
      flex: 1,
      field: 'name',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Имя</strong>,
    },
    {
      flex: 1,
      field: 'surName',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Фамилия</strong>,
    },
    {
      flex: 1,
      field: 'type',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Тип</strong>,
    },
    {
      flex: 1,
      field: 'city',
      sortable: false,
      columnAlign: true,
      minWidth: 130,
      renderCell: (params) => {
        return <div>{params.row.cityInfo?.name}</div>;
      },
      renderHeader: () => <strong>Город</strong>,
    },
    {
      flex: 1,
      field: 'raiting',
      sortable: false,
      minWidth: 130,
      renderCell: (params) => {
        const rating = params.row?.raiting;
        return (
          <Rating
            name='rating'
            defaultValue={parseFloat(rating)}
            precision={0.5}
            readOnly
          />
        );
      },
      renderHeader: () => <strong>Рейтинг</strong>,
    },
    {
      flex: 1,
      field: 'status',
      sortable: false,
      headerAlign: 'center',
      minWidth: 130,
      renderCell: (params) => {
        const styles = params.row.online ? 'green' : 'red';
        return (
          <strong className='orders-card-values' style={{ color: styles }}>
            {params.row.online ? 'online' : 'offline'}
          </strong>
        );
      },
      renderHeader: () => <strong>Статус</strong>,
    },
    {
      flex: 1,
      field: 'balance',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Баланс</strong>,
    },
    {
      flex: 1,
      field: 'appVersion',
      sortable: false,
      minWidth: 100,
      renderHeader: () => <strong>appVersion</strong>,
    },
    {
      flex: 1,
      field: 'cityList',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>cityList</strong>,
    },
    actionsColumn,
  ];

} 
export const getCouriersColumn = ({ actionsConfig, path }) => {
  const actionsColumn = {
    field: 'actions',
    sortable: false,
    width: 170,
    menubar: false,
    renderCell: (params) => (
      <div>
        {!actionsConfig ? null : (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`${path}/${params.id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRounded style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )}
      </div>
    ),
    renderHeader: () => <strong>{!actionsConfig ? '' : 'Действия'}</strong>,
  };
  return [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`${path}/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRounded style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip >
      )
    },
    {
      flex: 0.5,
      headerName: 'ID',
      field: 'id',
      sortable: false,
      minWidth: 130,
    },
    {
      flex: 1,
      field: 'name',
      sortable: false,
      minWidth: 130,
      renderHeader: () => <strong>Имя</strong>,
    },
    {
      flex: 1,
      field: 'balance',
      sortable: true,
      minWidth: 130,
      renderHeader: () => <strong>Баланс</strong>,
    },
    actionsColumn,
  ];

} 