export const headers = [
  'Имя',
  'Фамилия',
  'Номер',
  'Рейтинг',
  'Баланс',
  'Город',
  'Статус аккаунта',
  'Тип курьера',
];

export const typeOfOrder = [
  { id: 1, value: 'document', name: 'Документ' },
  { id: 2, value: 'medicine', name: 'Лекарство' },
  { id: 3, value: 'food', name: 'Еда' },
  { id: 4, value: 'small_box', name: 'Маленькая коробка' },
  { id: 5, value: 'box', name: 'Коробка' },
  { id: 6, value: 'large_box', name: 'Большая коробка' },
  { id: 7, value: 'Пакет', name: 'Пакет' },
  { id: 8, value: 'other', name: 'Другое' },
];

export const fieldsToSearchCourier = [
  'id',
  'name',
  'surName',
  'type',
  // 'cityInfo',
];


export const countOfOrdersCard = (totalOrders, totalCompletedOrders, totalReturnedOrders) => {
  return [
    {
      title: 'Кол-во активных заказов',
      value: totalOrders,
      borderColor: '#635BFF'
    },
    {
      title: 'Кол-во доставленных заказов',
      value: totalCompletedOrders,
      borderColor: '#15B79F'
    },
    {
      title: 'Кол-во возвратных заказов',
      value: totalReturnedOrders,
      borderColor: '#FB9C0C'
    },
  ]
};


export const courirerTransactionLabels = {
  admin_plus: 'Выдача денежных средств в подотчет в (1520)',
  services_revenue: 'Выручка от реализации услуг за доставку товара',
  returns_revenue: 'Возврат суммы выкупа',
  unsold_product_costs: 'Отмена выкупа',
  return_del_cost: 'Выручка от реализации услуг за  возвращаемый товар',
  detail_change: 'Корректировка выкупа',
  detail_change_usluga: 'Корректировка услуги доставки',
  admin_minus: 'Возврат подотчетных денежных средств (1520)',
  goods: 'Оплата за выкуп',
  fuel_compensation_couriers: 'Компенсация затрат на топливо курьеров',
  way_save_expense: 'Расходы по хранению и транспортные расходы по доставке',
  courier_service_expenses: 'Расходы по оплате услуг (курьера)',
  cancel_order: 'Отмена заказа',
  packaging_expenses: 'Расходы по упаковке товаров',
  salary_couriers_hourly: 'Заработная плата курьеров (повременщики)',
}

export const courirerTransactionPlusLabels = [
  { value: 'admin_plus', name: 'Выдача денежных средств в подотчет в (1520)' },
  { value: 'services_revenue', name: 'Выручка от реализации услуг за доставку товара' },
  { value: 'returns_revenue', name: 'Возврат суммы выкупа' },
  { value: 'unsold_product_costs', name: 'Отмена выкупа' },
  { value: 'return_del_cost', name: 'Выручка от реализации услуг за  возвращаемый товар' },
  { value: 'detail_change', name: 'Корректировка выкупа' },
  { value: 'detail_change_usluga', name: 'Корректировка услуги доставки' },
]

export const courirerTransactionMinusLabels = [
  { value: 'admin_minus', name: 'Возврат подотчетных денежных средств (1520)' },
  { value: 'goods', name: 'Оплата за выкуп' },
  { value: 'detail_change', name: 'Корректировка выкупа' },
  { value: 'detail_change_usluga', name: 'Корректировка услуги доставки' },
  { value: 'fuel_compensation_couriers', name: 'Компенсация затрат на топливо курьеров' },
  { value: 'way_save_expense', name: 'Расходы по хранению и транспортные расходы по доставке' },
  { value: 'courier_service_expenses', name: 'Расходы по оплате услуг (курьера)' },
  { value: 'cancel_order', name: 'Отмена заказа' },
  { value: 'packaging_expenses', name: 'Расходы по упаковке товаров' },
  { value: 'salary_couriers_hourly', name: 'Заработная плата курьеров (повременщики)' },
]