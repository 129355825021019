import React, { useEffect, useState } from 'react';
import { Header } from '../../../../components/Header/Header';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { Title } from '../../../../components/Title/Title';
import OrderGrid from '../../../../components/OrderGrid ';
import { couriersRef } from '../../../../utils/collectionRefs';
import { getDocs } from 'firebase/firestore';
import { TextField, MenuItem, Stack, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import { courierTransactionColumns } from './helpers/transactionsColumn';
import './scss/Transaction.scss'
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import axiosInstance from '../../../../configs/axiosInstance';
import SearchFilter from '../../../../components/SearchFilter';
import { courirerTransactionMinusLabels, courirerTransactionPlusLabels } from '../Couriers/constants';

const CouriersTransactionTest = ({ pageTitle }) => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [couriers, setCouriers] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({ type: '' });
  const [loading, setLoading] = useState(true);

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setPage(0)
  };

  const createOrderSortHandler = (field) => (event, newValue) => {
    if (newValue !== undefined) {
      sortOrderBy(field, newValue?.value || "");
    } else {
      sortOrderBy(field, event.target.value);
    }
  };


  const orderTypeToSort = createOrderSortHandler('type');
  const orderCourierToSort = createOrderSortHandler('courierPhone');

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleDateChange2 = (e) => setSelectedDate2(e.target.value);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };
  
  useEffect(() => {
    const fetchFilteredData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/user/courierTransaction/', {
          params: {
            type: selectedFilters.type === 'Все' ? '' : selectedFilters.type,
            courierPhone: selectedFilters.courierPhone === 'Все' ? '' : selectedFilters.courierPhone,
            dateFrom: selectedDate,
            dateTo: selectedDate2,
            page: page + 1,
          },
        });
        setTransactions(response.data.results);
        setTotalRowCount(response.data.count);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching filtered data:', error);
        setLoading(false);
      }
    };

    fetchFilteredData();
  }, [selectedFilters, selectedDate, selectedDate2, page, pageSize]);

  useEffect(() => {
    const fetchCouriers = async () => {
      try {
        const couriersSnapshot = await getDocs(couriersRef);
        const couriersData = couriersSnapshot.docs.map((doc) => ({ ...doc.data() }));
        setCouriers(couriersData);

      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchCouriers();
  }, []);

  const handleExport = async () => {
    try {
      const response = await axiosInstance(`/user/export/courier-transactions/`);

      if (response.data?.file) {
        const link = document.createElement('a');
        link.href = response.data.file;
        link.download = response.data.title || 'exported_file.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('Файл не найден в ответе');
      }
    } catch (error) {
      console.error('Ошибка при экспорте:', error);
    }
  };

  const combinedLabels = [
    ...courirerTransactionPlusLabels,
    ...courirerTransactionMinusLabels,
  ];

  // Убираем дубликаты по полю value
  const uniqueLabels = combinedLabels.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.value === value.value
    ))
  );

  const columns = courierTransactionColumns({ path: `/transaction/update` })

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Обороты счета (курьеры) ТЕСТ' />
        <Title
          title={'Обороты счета (курьеры) ТЕСТ'}
          icon={<MonetizationOnRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <Stack direction='row' gap={2.5} mb={2.5}>
            <BalanceCard
              title='Общее количество транзакций'
              value={totalRowCount}
              icon={NumbersRoundedIcon}
              iconColor='#635BFF'
            />
          </Stack>
          <div className='orders-sorting-header order-sort order-sort-transactions'>
            <div className='orders-sorting-header__search'>
              <TextField
                id='outlined-select-currency'
                select
                label='Сорт. по курьеру'
                fullWidth
                size='small'
                defaultValue=''
                onChange={orderCourierToSort}
              >
                <MenuItem value='Все'>Все</MenuItem>
                {couriers?.map((option, index) => (
                  <MenuItem key={index} value={option.phone}>
                    {option.name} ({option.phone})
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <SearchFilter
              sortKey="По типу расхода"
              defaultValue=""
              options={uniqueLabels}
              onChange={orderTypeToSort}
            />
            <div className='order-sort order-sort-date'>
              <label htmlFor='orderDate' className='orders-sort-date-label'>
                От
                <input
                  className='order-sort-dateInput'
                  type='date'
                  name='orderDate'
                  id='orderDate'
                  value={selectedDate || ''}
                  onChange={handleDateChange}
                />
              </label>
              <span></span>
              <label htmlFor='orderDate2' className='orders-sort-date-label'>
                До
                <input
                  className='order-sort-dateInput'
                  type='date'
                  name='orderDate2'
                  id='orderDate2'
                  value={selectedDate2 || ''}
                  onChange={handleDateChange2}
                />
              </label>
            </div>
          </div>
          <div className="orders-card-wrapper">
            <div className='orders-card-header'>
              <Button variant='black-contained' onClick={handleExport}>
                Экспорт
              </Button>
            </div>
            <OrderGrid
              orders={transactions}
              columns={columns}
              size={pageSize}
              page={page}
              loading={loading}
              usePagination={true}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              totalRowCount={totalRowCount}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CouriersTransactionTest;
