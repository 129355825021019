import { Autocomplete, TextField } from "@mui/material";
import './scss/OrderSort.scss';

const SearchFilter = ({ sortKey, defaultValue, options, onChange }) => {
  return (
    <div className='order-sort' style={{ width: '250px' }}>
      <Autocomplete
        fullWidth
        options={options || []}
        getOptionLabel={(option) => option?.name}
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        // value={options.find((opt) => opt.value === defaultValue) || null}
        onChange={onChange}
        size="small"
        renderInput={(params) => (
          <TextField {...params} label={sortKey} />
        )}
      />
    </div>
  );
};

export default SearchFilter;
