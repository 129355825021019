const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const numberToWords = (num) => {
  const units = ['', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'];
  const unitsFeminine = ['', 'одна', 'две', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'];
  const teens = ['десять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'];
  const tens = ['', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'];
  const hundreds = ['', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'];

  const thousands = [
    ['тысяча', 'тысячи', 'тысяч'], 
    ['миллион', 'миллиона', 'миллионов'],
    ['миллиард', 'миллиарда', 'миллиардов'],
  ];

  if (num === 0 || !num) return 'ноль сом 00 тыйын';

  let isNegative = num < 0;
  if (isNegative) num = Math.abs(num);

  let result = '';
  const som = Math.floor(num);
  const tiyn = Math.round((num - som) * 100);

  const getDeclension = (n, forms) => {
    if (n % 10 === 1 && n % 100 !== 11) return forms[0];
    if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) return forms[1];
    return forms[2];
  };

  // Разбиваем число на тройки
  let parts = [];
  let numCopy = som;
  while (numCopy > 0) {
    parts.push(numCopy % 1000);
    numCopy = Math.floor(numCopy / 1000);
  }

  for (let i = parts.length - 1; i >= 0; i--) {
    let chunk = parts[i];
    if (chunk === 0) continue;

    let h = Math.floor(chunk / 100);
    let t = Math.floor((chunk % 100) / 10);
    let u = chunk % 10;

    if (h > 0) result += `${hundreds[h]} `;

    if (t === 1) {
      result += teens[u];
    } else {
      if (t > 1) result += `${tens[t]} `;
      if (i === 1) {
        // Для тысяч (женский род)
        result += unitsFeminine[u];
      } else {
        result += units[u];
      }
    }

    if (i > 0) {
      result += ` ${getDeclension(chunk, thousands[i - 1])} `;
    }
  }

  result = result.trim() + ' сом';
  result += tiyn > 0 ? ` ${tiyn} тыйын` : ' 00 тыйын';

  if (isNegative) {
    result = `${result}`;
  }

  return capitalizeFirstLetter(result.trim());
};

