import React from 'react'
import { Helmet } from 'react-helmet'
import { Header } from '../../../../components/Header/Header'
import { Title } from '../../../../components/Title/Title'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import OrderGrid from '../../../../components/OrderGrid ';
import { kassaCategoryColumn } from './constants/getKassaColumn';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import { useFetchData } from '../../../../hooks/useFetchData';

const KassaCategory = ({ pageTitle }) => {
  const { data, loading, error } = useFetchData('/category/kassaCategory/')

  const columns = kassaCategoryColumn()
  const totalBalance = data?.results?.reduce((acc, item) => acc + (item.balance || 0), 0);

  return (
    <div className='containerr'>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Header initial='Банк и касса' />
      <Title
        title='Банк и касса'
        icon={<CurrencyExchangeIcon fontSize='meduim' />}
      />
      <div className="container-inner">
        <div className='order-statistics-container'>
          <BalanceCard
            title='Общая сумма'
            value={totalBalance}
            icon={NumbersRoundedIcon}
            iconColor='#635BFF'
          />
        </div>

        <div className='orders-card-wrapper'>
          {/* <div className='orders-card-header'>
            <div className='orders-card-parent'>
              <div className='orders-card-parent__btns'>
                {USERTYPE === 'Админ' && (
                  <Button variant='black-contained' onClick={() => navigate('/kassa/add')}>
                    Новая касса +
                  </Button>
                )}
              </div>
            </div>
          </div> */}
          {error ? <h1>Ошибка :(</h1> : (
            <OrderGrid
              orders={data.results}
              columns={columns}
              loading={loading}
              size={20}
              usePagination={false}
            />
          )}


        </div>
      </div>
    </div>
  )
}

export default KassaCategory