import React, { useEffect, useState } from 'react';
import { Header } from '../../../../components/Header/Header';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import { Title } from '../../../../components/Title/Title';
import OrderGrid from '../../../../components/OrderGrid ';
import {  transactionsRef } from '../../../../utils/collectionRefs';
import { getAggregateFromServer, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where, sum } from 'firebase/firestore';
import {  Stack } from '@mui/material';
import { Helmet } from 'react-helmet';
import { transActionColumns } from './helpers/transactionsColumn';
import OrderSort from '../../../../components/OrderSort';
import { typeOfTransactionLables } from './constants';
import { Loader } from '../../../../components/Loader/Loader';
import './scss/Transaction.scss'
import { headersFotTransactions2 } from '../Orders/constants';
import { transactionExcelDataFormatter } from './helpers/excelDataFormatter';
import ExportToExcel from '../../../../components/Buttons/ExportToExcell';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';

const CouriersTransaction = ({ pageTitle }) => {
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false)
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({ type: '' });

  const USERNAME = localStorage.getItem('userName');

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);

      let q = query(transactionsRef, orderBy('date', 'desc'), where('user', '==', USERNAME));
      let aggregateQuery = query(transactionsRef, where('user', '==', USERNAME));

      Object.keys(selectedFilters).forEach((field) => {
        const value = selectedFilters[field];
        if (value !== 'Все' && value !== '') {
          q = query(q, where(field, '==', value));
        }
      });

      if (selectedDate && selectedDate2) {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate2);
        endOfDay.setHours(23, 59, 59, 999);
        q = query(q, where('date', '>=', startOfDay), where('date', '<=', endOfDay));
      }

      if (page > 0 && lastVisible) {
        q = query(q, startAfter(lastVisible), limit(pageSize));
      } else {
        q = query(q, limit(pageSize));
        setLastVisible(null);
      }

      try {
        let countQuery = query(transactionsRef, where('user', '==', USERNAME));

        Object.keys(selectedFilters).forEach((field) => {
          const value = selectedFilters[field];
          if (value !== 'Все' && value !== '') {
            countQuery = query(countQuery, where(field, '==', value));
            aggregateQuery = query(aggregateQuery, where(field, '==', value));
          }
        });

        if (selectedDate && selectedDate2) {
          const startOfDay = new Date(selectedDate);
          startOfDay.setHours(0, 0, 0, 0);
          const endOfDay = new Date(selectedDate2);
          endOfDay.setHours(23, 59, 59, 999);
          countQuery = query(countQuery, where('date', '>=', startOfDay), where('date', '<=', endOfDay));
          aggregateQuery = query(aggregateQuery, where('date', '>=', startOfDay), where('date', '<=', endOfDay));
        }

        const countSnapshot = await getCountFromServer(countQuery);
        setTotalRowCount(countSnapshot.data().count);

        const aggregateSnap = await getAggregateFromServer(aggregateQuery, {
          totalSum: sum('sum'),
        });
        console.log(aggregateSnap.data().totalSum)

        const transactionSnapshot = await getDocs(q);
        const transactionData = transactionSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        if (transactionSnapshot.docs.length > 0) {
          setLastVisible(transactionSnapshot.docs[transactionSnapshot.docs.length - 1]);
        } else {
          setLastVisible(null);
        }

        setTransactions(transactionData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [selectedFilters, selectedDate, selectedDate2, page, pageSize]);

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setPage(0)
  };

  const createOrderSortHandler = (field) => (event) => {
    sortOrderBy(field, event.target.value);
  }

  const orderTypeToSort = createOrderSortHandler('type');

  const handleDateChange = (e) => setSelectedDate(e.target.value);
  const handleDateChange2 = (e) => setSelectedDate2(e.target.value);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
    setLastVisible(null);
  };

  const columns = transActionColumns({ path: `/transaction/update` })
  
  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Обороты счета (курьеры)' />
        <Title
          title={'Обороты счета (курьеры)'}
          icon={<MonetizationOnRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <Stack direction='row' gap={2.5} mb={2.5}>
            <BalanceCard
              title='Общее количество транзакций'
              value={totalRowCount}
              icon={NumbersRoundedIcon}
              iconColor='#635BFF'
            />
          </Stack>
          <div className='orders-sorting-header order-sort order-sort-transactions'>
            <OrderSort
              sortKey='Сорт. по типу расхода'
              defaultValue=''
              options={typeOfTransactionLables}
              onChange={orderTypeToSort}
            />
            <div className='order-sort order-sort-date'>
              <label htmlFor='orderDate' className='orders-sort-date-label'>
                От
                <input
                  className='order-sort-dateInput'
                  type='date'
                  name='orderDate'
                  id='orderDate'
                  value={selectedDate || ''}
                  onChange={handleDateChange}
                />
              </label>
              <span></span>
              <label htmlFor='orderDate2' className='orders-sort-date-label'>
                До
                <input
                  className='order-sort-dateInput'
                  type='date'
                  name='orderDate2'
                  id='orderDate2'
                  value={selectedDate2 || ''}
                  onChange={handleDateChange2}
                />
              </label>
            </div>
          </div>
          <div className="orders-card-wrapper">
            <div className="orders-card-header">
              <ExportToExcel
                firestoreRef={transactionsRef}
                selectedFilters={selectedFilters}
                headers={headersFotTransactions2}
                selectedDate={selectedDate}
                selectedDate2={selectedDate2}
                dateField='date'
                fileName='Список транзакций'
                dataFormatter={transactionExcelDataFormatter}
              />
            </div>
            {loading
              ? <Loader />
              : <OrderGrid
                orders={transactions}
                columns={columns}
                size={pageSize}
                page={page}
                usePagination={true}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                totalRowCount={totalRowCount}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CouriersTransaction;
