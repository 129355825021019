import React from "react";
import { dateFormatter } from "../../../../../utils/dateFormatter";
import NoteAltRoundedIcon from '@mui/icons-material/NoteAltRounded';
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { transactionStatusLabels, transactionExpenseLabels } from "../constants";
import AutoLink from "../../../../../components/AutoLink";
import dayjs from "dayjs";
import { courirerTransactionLabels } from "../../Couriers/constants";


export const transActionColumns = ({ path, test }) => {
  const currentDate = Date.now()
  const userType = localStorage.getItem('userType');
  return [
    {
      width: 150,
      field: 'date',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params.row?.date?.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата</strong>,
      disableColumnMenu: false,
    },
    {
      width: 130,
      field: userType === 'Курьер' ? 'courierPhone' : 'user',
      sortable: false,
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 130,
      field: 'sender',
      sortable: false,
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 130,
      field: 'receiver',
      sortable: false,
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 350,
      field: 'type',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Тип операции</strong>,
      renderCell: (params) => {
        return <div> {!test ? transactionStatusLabels[params.row.type] : transactionExpenseLabels[params.row.type]}</div>;
      },
    },
    {
      width: 140,
      field: 'balanceBefore',
      sortable: false,
      headerAlign: 'center',
      columnAlign: true,
      renderCell: (params) => {
        return (
          <strong className='orders-card-values balanceBefore'>
            {params.value.toLocaleString('ru-RU')}с
          </strong>
        );
      },
      renderHeader: () => <strong>Баланс до</strong>,
    },
    {
      width: 140,
      field: 'sum',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <strong className='orders-card-values sum'>{params.value.toLocaleString('ru-RU')}с</strong>
        );
      },
      renderHeader: () => <strong>Cумма</strong>,
    },
    {
      width: 140,
      field: 'balanceAfter',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <strong className='orders-card-values balanceAfter'>
            {params.value.toLocaleString('ru-RU')}с
          </strong>
        );
      },
      renderHeader: () => <strong>Баланс после</strong>,
    },
    {
      width: 600,
      field: 'comment',
      sortable: false,
      renderHeader: () => <strong>Комментарии</strong>,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'wrap' }}>
            <AutoLink text={params.row.comment} />
          </div>
        );
      },
    },
    {
      width: 200,
      field: userType ? 'author' : 'creatorName',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Создатель</strong>,
      renderCell: (params) => {
        const creatorName = params?.row?.creatorName
        const creator = params?.row?.creator
        return (
          <strong>
            {creator} - {creatorName}
          </strong>
        );
      },
    },
    ...(userType !== 'Курьер' ? [{
      field: 'actions',
      sortable: false,
      width: 100,
      menubar: false,
      renderCell: (params) => {
        const createdDate = params?.row?.date?.seconds * 1000;
        const difference = Math.abs(currentDate - createdDate);
        const lessThanOneDay = difference < 30 * 24 * 60 * 60 * 1000;
        return (
          <div>
            {lessThanOneDay ? (
              <Tooltip title='Редактировать' arrow>
                <Link to={`${path}/${params.id}`} target="_blank">
                  <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
                </Link>
              </Tooltip>
            ) : null}
          </div>
        )
      },
      renderHeader: () => <strong>Действия</strong>,
    }] : []),
  ];
}

export const courierTransactionColumns = ({ path, test }) => {
  return [
    {
      width: 150,
      field: 'dateTransaction',
      sortable: true,
      renderCell: (params) => {
        return <div>{dayjs(params.value).format('DD-MM-YYYY HH:mm')}</div>;
      },
      renderHeader: () => <strong>Дата</strong>,
      disableColumnMenu: false,
    },
    {
      width: 130,
      field: 'courierPhone',
      sortable: false,
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 350,
      field: 'type',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Тип операции</strong>,
      renderCell: (params) => {
        return <div> {courirerTransactionLabels[params.value]}</div>;
      },
    },
    {
      width: 140,
      field: 'balanceBefore',
      sortable: false,
      headerAlign: 'center',
      columnAlign: true,
      renderCell: (params) => {
        return (
          <strong className='orders-card-values balanceBefore'>
            {params.value.toLocaleString('ru-RU')}с
          </strong>
        );
      },
      renderHeader: () => <strong>Сальдо начало</strong>,
    },
    {
      flex: 1,
      field: 'summaPlus',
      minWidth: 100,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Поступило</strong>,
      valueGetter: (params) => (params.row.summa > 0 ? params.row.summa : 0),
      valueFormatter: (params) => params.value ? params.value.toLocaleString('ru-RU') : '0',
    },
    {
      flex: 1,
      field: 'summaMinus',
      minWidth: 100,
      disableColumnMenu: false,
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Списано</strong>,
      valueGetter: (params) => (params.row.summa < 0 ? Math.abs(params.row.summa) : 0),
      valueFormatter: (params) => params.value ? params.value.toLocaleString('ru-RU') : '0',
    },
    {
      width: 140,
      field: 'summa',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <strong className='orders-card-values sum'>{params.value.toLocaleString('ru-RU')}с</strong>
        );
      },
      renderHeader: () => <strong>Сумма</strong>,
    },
    {
      width: 140,
      field: 'balanceAfter',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <strong className='orders-card-values balanceAfter'>
            {params.value.toLocaleString('ru-RU')}с
          </strong>
        );
      },
      renderHeader: () => <strong>Сальдо конец</strong>,
    },
    {
      width: 200,
      field: 'sender',
      sortable: false,
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 150,
      field: 'receiver',
      sortable: false,
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 600,
      field: 'comment',
      sortable: false,
      renderHeader: () => <strong>Комментарии</strong>,
      renderCell: (params) => {
        return (
          <div style={{ whiteSpace: 'wrap' }}>
            <AutoLink text={params.row.comment} />
          </div>
        );
      },
    },
    {
      width: 200,
      field: 'author',
      sortable: false,
      headerAlign: 'center',
      cellClassName: 'centered-cell',
      renderHeader: () => <strong>Создатель</strong>,
      renderCell: (params) => (
        <strong>
          {params.value}
        </strong>
      ),
    },
    // ...(userType !== 'Курьер' ? [{
    //   field: 'actions',
    //   sortable: false,
    //   width: 100,
    //   menubar: false,
    //   renderCell: (params) => {
    //     const createdDate = params?.row?.date?.seconds * 1000;
    //     const difference = Math.abs(currentDate - createdDate);
    //     const lessThanOneDay = difference < 30 * 24 * 60 * 60 * 1000;
    //     return (
    //       <div>
    //         {lessThanOneDay ? (
    //           <Tooltip title='Редактировать' arrow>
    //             <Link to={`${path}/${params.id}`} target="_blank">
    //               <NoteAltRoundedIcon style={{ color: '#000b1f' }} />
    //             </Link>
    //           </Tooltip>
    //         ) : null}
    //       </div>
    //     )
    //   },
    //   renderHeader: () => <strong>Действия</strong>,
    // }] : []),
  ];
} 