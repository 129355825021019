import React from 'react';
import './Header.scss';
import { MdNavigateNext } from 'react-icons/md';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../../provider/SidebarProvider';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import MenuOpenTwoToneIcon from '@mui/icons-material/MenuOpenTwoTone';

export const Header = ({ previous, initial }) => {
  const navigate = useNavigate()

  const { isCollapsed, toggleSidebar } = useSidebar();

  //  const pathMapping = {
  //   'Профиль': '',
  //   'Статистика': 'dashboard',
  //   'Искать везде': 'search',
  //   'Все заказы': 'allOrders',
  //   'Активные заказы в реальном времени': 'orders',
  //   'Список возвратов': 'returned-orders',
  //   'Детали возврата': 'order/returned/details/:id',
  //   'Активные заказы': 'orderss',
  //   'Список измененных заказов': 'order/edited',
  //   'Список завершенных заказов': 'order/completed',
  //   'Детали завершенных заказов': 'order/completed/details/:id',
  //   'Список отмененных заказов': 'order/cancelled',
  //   'Список удаленных заказов': 'order/deleted',
  //   'Детали отменненого заказа': 'order/cancelled/details/:id',
  //   'Список долгих заказов': 'longorders',
  //   'Список заказов (Счет фактура)': 'schetFaktura',
  //   'Список долгов': 'order/debt',
  //   'Добавить заказ': 'order/add',
  //   'Детали заказа': 'order/details/:id',
  //   'Редактирование заказа': 'order/update/:id',
  //   'История заказа': 'order/history/:collectionId/:id',
  //   'Заметки заказа': 'order/notes/:id',
  //   'История пользователя': 'userHistory/:role/:id',
  //   'Список клиентов': 'clients',
  //   'Детали клиента': 'client/details/:id',
  //   'Добавить курьера': 'courier/add',
  //   'Список курьеров': 'couriers',
  //   'Карта курьеров': 'courier/map',
  //   'Детали курьера': 'courier/details/:id',
  //   'Редактирование курьера': 'courier/update/:id',
  //   'Добавление агента': 'agent/add',
  //   'Список агентов': 'agents',
  //   'Детали агента': 'agent/details/:id',
  //   'Редактирование агента': 'agent/update/:id',
  //   'Список транзакций': 'transaction',
  //   'Редактирование транзакции': 'transaction/update/:id',
  //   'Банк и касса': 'bank',
  //   'Список касс': 'bank/:kassaID',
  //   'Детали кассы': 'kassa/details/:id',
  //   'Добавить кассу': 'kassa/add',
  //   'Редактирование кассы': 'kassa/update/:id',
  //   'Список городов': 'cities',
  //   'Детали города': 'city/details/:id',
  //   'Список заказов (Город)': 'cityOrders',
  //   'Добавить заказ (Город)': 'cityOrder/add',
  //   'Детали заказа (Город)': 'cityOrder/details/:id',
  //   'Редактирование заказа (Город)': 'cityOrder/update/:id',
  //   'История заказа (Город)': 'cityOrder/history/:id',
  //   'Редактирование города': 'city/update/:id',
  //   'Редактирование района': 'village/update/:id',
  //   'Часто задаваемые вопросы': 'faq',
  //   'Магазины': 'stores',
  //   'Детали магазина': 'stores/:id',
  //   'Детали заказа магазина': 'storeOrders/:id',
  //   'Категории': 'categories',
  //   'Редактирование категории': 'categories/:id',
  //   'Добавить категорию': 'categories/add',
  //   'Подкатегории': 'subCategories',
  //   'Редактирование подкатегории': 'subCategories/:id',
  //   'Добавить подкатегорию': 'subCategories/add',
  //   'Подподкатегории': 'subSubCategories',
  //   'Добавить подподкатегорию': 'subSubCategories/add',
  //   'Редактирование подподкатегории': 'subSubCategories/:id',
  //   'Цвета': 'colors',
  //   'Добавить цвет': 'colors/add',
  //   'Редактирование цвета': 'colors/:id',
  //   'Размеры': 'sizes',
  //   'Добавить размер': 'sizes/add',
  //   'Редактирование размера': 'sizes/:id',
  //   'Бренды': 'brands',
  //   'Добавить бренд': 'brands/add',
  //   'Редактирование бренда': 'brands/:id',
  // };

  return (
    <>
      <div className="main-header">
        <div className="header-page-navigation">
          {/* <Breadcrumbs
            separator={<MdNavigateNext size={20} />}
            aria-label="breadcrumb"
          >
            <MuiLink
              component={Link}
              to="/"
              underline="hover"
              color="inherit"
            >
              Профиль
            </MuiLink>
            {previous && (
              <MuiLink
                component={Link}
                to={`/${pathMapping[previous] || previous.toLowerCase()}`}
                underline="hover"
                color="inherit"
              >
                {previous}
              </MuiLink>
            )}
            <Typography color="text.primary">{initial}</Typography>
          </Breadcrumbs> */}
          <div>
            <h3>{previous}</h3>
            <MdNavigateNext />
            <h3>{initial}</h3>
          </div>
        </div>

        <div>
          <Tooltip
            title={isCollapsed ? "Открыть боковую панель" : "Закрыть боковую панель"}
            placement="top"
            arrow
            placeholder=""
          >
            <IconButton onClick={toggleSidebar} className="toggle-sidebar-button">
              {isCollapsed ? <MenuTwoToneIcon /> : <MenuOpenTwoToneIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Часто задавеамы вопросы" placement="top" arrow placeholder="">
            <IconButton onClick={() => navigate("/faq")}>
              <HelpRoundedIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>

      </div>
    </>
  );
};
