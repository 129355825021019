import React, { useRef } from 'react'
import '../scss/Form.scss'
import { Button, Stack } from '@mui/material';
import { PrintRounded } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import dayjs from 'dayjs';
import { numberToWords } from './helpers/numberToWords';

const Pko = ({ formData }) => {
  const pkoRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => pkoRef.current,
  });

  return (
    <Stack display='flex' direction='column' mt={2}>
      <div>
        <Button
          variant='black-contained'
          endIcon={<PrintRounded />}
          onClick={handlePrint}
        >
          печать
        </Button>
      </div>
      <div ref={pkoRef} className="pko-container">
        <div className="left">
          <div className="top-header">
            <div>
              <p>Унифицированная форма КО-1</p>
              <p>Утверждена постановлением Нацстаткомитета</p>
              <p>Кыргызской Республики от 07.04.03 №4</p>
            </div>
          </div>
          <div className='section-1'>
            <table className='sec-1-left-table'>
              <thead>
                <tr>
                  <th>ОсОО "Айла Экспресс"</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    организация
                  </td>
                </tr>
              </tbody>
            </table>
            <p>Форма по ГКУД по ОКПО</p>
            <table className='sec-1-right-table'>
              <tbody>
                <tr>
                  <td>Код</td>
                </tr>
                <tr>
                  <td>0310001</td>
                </tr>
                <tr>
                  <td>32544049</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='section-2'>
            <table className='sec-2-table'>
              <tbody>
                <tr>
                  <td>подразделение</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section-3">
            <h2>Приходный кассовый ордер</h2>
            <table className='sec-3-table'>
              <tbody>
                <tr>
                  <td>Номер документа</td>
                  <td>Дата составления</td>
                </tr>
                <tr>
                  <td>13</td>
                  <td>{!formData ? '' : dayjs(formData?.dateTransaction).format('DD/MM/YYYY')}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section-4">
            <table className='sec-4-table'>
              <tbody>
                <tr className='sec-4-table-header'>
                  <th className='debet'>Дебет</th>
                  <th colSpan="3" className='credit'>Кредит</th>
                  <th>Сумма</th>
                  <th className='code'>Код целевого назначения</th>
                </tr>
                <tr>
                  <th className='none' rowSpan="2">1100</th>
                  <th>код структурного подразделения</th>
                  <th>корр.счет, субсчет</th>
                  <th>код ан. учета</th>
                  <td className='valute'>KGS</td>
                  <th rowSpan="2"><br /></th>
                </tr>
                <tr className='thick-border'>
                  <td><br /></td>
                  <td>3210</td>
                  <td><br /></td>
                  <td>{formData?.summa}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="section-5">
            <table className='sec-5-table'>
              <tbody>
                <tr style={{ marginTop: '10px' }}>
                  <td rowSpan="2">Принято от:</td>
                  <td className="underline">
                    <input type="text" value={formData?.acceptedFrom} />
                  </td>
                </tr>
                <tr>
                  <td className='note'>фамилия, имя , отчество</td>
                </tr>
                <tr>
                  <td>Основание:</td>
                  <td className="underline">
                    <input type="text" value={formData?.basics}/>
                  </td>
                </tr>
                <tr style={{ marginTop: '10px' }}>
                  <td rowSpan="2">Сумма:</td>
                  <td className="underline">
                    <input type="text" value={numberToWords(formData?.summa)} />
                  </td>
                </tr>
                <tr>
                  <td className='note'>прописью</td>
                </tr>
                <tr>
                  <td>В том числе:</td>
                  <td className="underline">
                    <input type="text" />
                  </td>
                </tr>
                <tr style={{ marginTop: '10px' }}>
                  <td>Приложение:</td>
                  <td className="underline">
                    <input type="text" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='section-6'>
            <div className='section-content'>
              <div>
                <h4>Главный бухгалтер</h4>
                <span>
                  ____________
                  <br />
                  <p>подпись</p>
                </span>
              </div>

              <div>
                <span>
                  ____________________________________________________________
                  <br />
                  <p>расшифровка подписи</p>
                </span>
              </div>
            </div>
            <div className='section-content'>
              <div>
                <h4>Получил кассир</h4>
                <span>
                  ____________
                  <br />
                  <p>подпись</p>
                </span>
              </div>

              <div>
                <span>
                  ____________________________________________________________
                  <br />
                  <p>расшифровка подписи</p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className='section-1'>
            <table className='sec-1-left-table'>
              <thead>
                <tr>
                  <th>ОсОО "Айла Экспресс"</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    организация
                  </td>
                </tr>
              </tbody>
            </table>
            <h2>Квитанция</h2>
            <table className='sec-1-right-table'>
              <tbody>
                <tr>
                  <td style={{ width: " 35.6824%", textAlign: "right" }}>к ПКО №</td>
                  <td style={{ width: " 65.3176%", textAlign: "center" }}><strong>13</strong></td>
                </tr>
                <tr>
                  <td style={{ width: " 35.6824%", textAlign: "right" }}>от</td>
                  <td style={{ width: " 65.3176%", textAlign: "left" }}><strong>{!formData ? '' : dayjs(formData?.dateTransaction).format('DD/MM/YYYY')}</strong></td>

                </tr>
              </tbody>
            </table>
          </div>
          <div className="section-2">
            <p>Принято от: {formData?.acceptedFrom}</p>
            <p>Основание:</p>
          </div>
          <div className="section-3">
            <table>
              <tbody>
                <tr>
                  <td style={{ width: '40.7564%', textAlign: 'right' }}>
                    <div style={{ textAlign: 'left' }}>Сумма:</div>
                  </td>
                  <td style={{ width: '59.2436%', textAlign: 'center' }}>
                    <div style={{ textAlign: 'left', borderBottom: '2px solid #000' }}>
                      <strong>{formData?.summa} сом 00 тыйын</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '40.7564%', textAlign: 'right' }}>
                    <div style={{ textAlign: 'left' }}>KGS</div>
                  </td>
                  <td style={{ width: '59.2436%', textAlign: 'left' }}>
                    <div style={{ textAlign: 'center', fontSize: '13px' }}>цифрами</div>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    style={{
                      width: '100%',
                      height: '60px',
                      verticalAlign: 'bottom',
                      borderBottom: '2px solid #000'
                    }}
                    colSpan="2"
                  >
                    {numberToWords(formData?.summa)}
                  </td>
                </tr>
              </tfoot>
            </table>
            <strong className='sec-3-block'>
              М.П (штамп) {!formData ? '' : dayjs(formData?.dateTransaction).format('DD/MM/YYYY')}
            </strong>
          </div>
          <div className="section-4">
            <h4>Главный бухгалтер</h4>
            <div className='section-content'>
              <div>
                <span>
                  ____________
                  <br />
                  <p>подпись</p>
                </span>
              </div>

              <div>
                <span>
                  _________________________
                  <br />
                  <p>расшифровка подписи</p>
                </span>
              </div>
            </div>
            <h4>Кассир</h4>
            <div className='section-content'>
              <div>
                <span>
                  ____________
                  <br />
                  <p>подпись</p>
                </span>
              </div>

              <div>
                <span>
                  _________________________
                  <br />
                  <p>расшифровка подписи</p>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Stack>
  )
}
export default Pko

